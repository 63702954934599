import React from 'react'
import img1 from "../../../assets/img/iiot/adrien-olichon-RCAhiGJsUUE-unsplash.jpg"
export default function MainSection1() {
  return (
    <div>
         <section class=" mt-5  pt-20 pb-20 px-4  relative">
          <div
            class="absolute  w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat bg-fixed"
            style={{
              backgroundImage:`url(${img1})`,
            }}
          ></div>
          <div className="max-w-4xl  mb-10 md:mx-auto text-center   lg:max-w-[62rem] md:mb-12">
            <p className="relative   tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            AeroEdge IIoT, the flagship product from AeroAegis Inc., represents a breakthrough in industrial automation and intelligence. Designed to seamlessly integrate with any Industrial IoT (IIoT) sensors and systems, AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration. This platform empowers industries to enhance operational efficiency, reduce downtime, and optimize maintenance strategies, all while ensuring robust data security and compliance.
            </p>
          </div>
        </section>
          
    </div>
  )
}
