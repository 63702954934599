import React from 'react'
import customization_img from "../../../assets/img/iiot/MicrosoftTeams-image.png"
import industrial_environment from "../../../assets/img/iiot/pexels-ramaz-bluashvili-26344937-6855582.jpg";
import edge_gateway from "../../../assets/img/iiot/pexels-tima-miroshnichenko-5380664.jpg";
import aeroedge_cloud from "../../../assets/img/iiot/108504.jpg";


export default function Deployment() {
  return (
    <div>
	<section className=" dark:bg-gray-800 dark:text-gray-100">
    <span class="mb-10  pt-8 block text-center text-4xl font-bold ">
            How It <span className='text-[#23307F]'> Works</span>
              <div>
              <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black">
               </p></div>
              </span>

	  <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-col">
	  <div className="">
		  <div className="flex items-center justify-center p-2 md:p-8">
			<img
			  src={customization_img}
			  alt=""
			  className="rounded-lg w-screen min-h-full "
			/>
		  </div>
		</div>
		<div className="flex flex-col justify-around px-6 py-4 space-y-3  font-medium rounded-sm ">
		  
		  <div class="grid grid-cols-1 gap-8 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
			
			<div class="min-h-screen flex justify-center items-center">
              <div class="container flex justify-center">
                <div class="max-w-sm ">
                  <div class="bg-white relative shadow-lg hover:shadow-xl transition duration-500 rounded-lg">
                    <img class="rounded-t-lg" src={industrial_environment} alt="" />
                    <div class="py-6 px-8 rounded-lg bg-white">
                      <h1 class="text-gray-700 font-bold text-2xl mb-3 hover:text-gray-900 hover:cursor-pointer text-center">Industrial Environment</h1>
                      <p class="text-gray-700 tracking-wide">This includes the IIoT devices like sensors and actuators that gather data from the field.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

			  <div class="min-h-screen flex justify-center items-center">
                  <div class="container flex justify-center">
                    <div class="max-w-sm ">
                      <div class="bg-white relative shadow-lg hover:shadow-xl transition duration-500 rounded-lg">
                        <img class="rounded-t-lg" src={edge_gateway} alt="" />
                        <div class="py-3 px-8 rounded-lg bg-white">
                          <h1 class="text-gray-700 font-bold text-2xl mb-3 hover:text-gray-900 hover:cursor-pointer text-center">Edge Gateway</h1>
                          <p class="text-gray-700 tracking-wide">Located near the industrial environment, it performs initial data processing and aggregation, minimizing the data sent to the cloud.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


			  <div class="min-h-screen flex justify-center items-center">
              <div class="container flex justify-center">
                <div class="max-w-sm ">
                  <div class="bg-white relative shadow-lg hover:shadow-xl transition duration-500 rounded-lg">
                    <img class="rounded-t-lg" src={aeroedge_cloud} alt="" />
                    <div class="py-6 px-8 rounded-lg bg-white">
                      <h1 class="text-gray-700 font-bold text-2xl mb-3 hover:text-gray-900 hover:cursor-pointer text-center">AeroEdge Cloud</h1>
                      <p class="text-gray-700 tracking-wide">This is where the heavy lifting occurs, with advanced analytics, data storage, and user interaction functionalities.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Edge Gateway</h1>

                <p class="text-gray-500 dark:text-gray-300">
				Located near the industrial environment, it performs initial data processing and aggregation, minimizing the data sent to the cloud.

                </p>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">AeroEdge Cloud</h1>

                <p class="text-gray-500 dark:text-gray-300">
				This is where the heavy lifting occurs, with advanced analytics, data storage, and user interaction functionalities.

                </p>
            </div> */}
        </div>    	
		</div>
	  </div>
	</section>
  </div>
  )
}
