import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import Section_one from "./section_one"
import Section_two from "./section_two"
import Section_three from "./section_three"
import CTA from "./cta"
import CTA1 from "./cta1"


export default function index() {
  return (
    <div>
      
        <Section_one />
        <AnimationRevealPage>
        <CTA />
        <Section_two />
        <CTA1 />
        <Section_three />
      </AnimationRevealPage>
    </div>
  )
}
