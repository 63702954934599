import React from "react";
import rpa_img from "../../../assets/img/Consulting/rpa.jpg";
export default function SectionOne() {
  return (
    <div>
      <section className=" dark:text-gray-100">
        <span class="mb-10 block text-center text-2xl font-bold text-[#23307F]">
          Core Offerings
          <div>
            <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black"></p>
          </div>
        </span>

        <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
          <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Robotic Process Automation (RPA)</h3>
				<p className="mt-3 text-lg dark:text-gray-400">
        Our approach to RPA is centered around leveraging leading platforms such as UIPath and Pega
RPA to automate repetitive, rule-based tasks that traditionally require manual input. By
deploying software robots that mimic human actions, we enable businesses to significantly
reduce processing times, eliminate errors, and free up valuable human resources for more
strategic tasks.
        </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">UIPath Automation</h4>
							<p className="mt-2 dark:text-gray-400">
              Utilizing UIPath, we design, deploy, and manage a digital workforce
that performs a wide range of administrative and operational tasks, from data entry and
processing to complex workflow executions. UiPath's flexible and intuitive platform
allows us to create custom automation solutions that align perfectly with your business
requirements.

              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Pega RPA</h4>
							<p className="mt-2 dark:text-gray-400">
              With Pega RPA, we focus on streamlining business processes that involve
complex decision-making and interactions across disparate systems. Pega's advanced
capabilities enable us to automate these processes, ensuring consistency, speed, and
accuracy in every task performed.
              </p>
						</div>
					</div>
					
				</div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={rpa_img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
