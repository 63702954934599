import React from "react";
import support_vid from "../../../assets/videos/AutomationRPA/support.mp4";
import measure_vid from "../../../assets/videos/AutomationRPA/measure.mp4";
import execution_vid from "../../../assets/videos/AutomationRPA/execution.mp4";
export default function MainSection1() {
  return (
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">What We Do</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-[#23307f] scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md">
            "Embodiment of the Old Adage, 'Work{" "}
            <span className=" ml-3"> Smarter, not Harder'."</span>
          </p>
        </div>
        <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={measure_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-10 lg:mt-28 text-center justify-center align-content-center font-semibold text-3xl group-hover:hidden'>Map and Measure</h1>
        </div>
              <div className="absolute inset-0 px-6 py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">
                  Map and Measure
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Find a way to identify what processes can be automated within
                  your business giving us a clearer understanding of the
                  existing processes in place and the systems driving them, as
                  well as the human interactions needed to perform these tasks.
                </p>
              </div>
            </div>
          </a>
          <a aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={execution_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-2 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-10 lg:mt-28 text-center justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Execution and Delivery</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-2 text-lg font-bold text-gray-100">
                  Execution and Delivery
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Implementation of process automation if the requirement is a
                  simple manual task, or implementation of intelligent process
                  automation if it requires decision making at in subsequent
                  stages along the chain.
                </p>
              </div>
            </div>
          </a>
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={support_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-2 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-10 lg:mt-28 text-center justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Maintain and Support</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-2 text-lg  font-bold text-gray-100">
                  Maintain and Support
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Continuous tweaking and improving to continue to streamline
                  processes in the long run, and also to provide support in case
                  of any failovers or errors.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
