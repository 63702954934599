import React from 'react'
import { Link } from 'react-router-dom';
import laptop_img from '../../assets/img/Whitepapers/smartchaos.jpg'

export default function Section2() {
  return (
    <div>
      <section class="relative flex items-center w-full bg-white">
        <div class="relative items-center w-full px-5 py-24 mx-auto md:px-12 lg:px-16 max-w-7xl">
          <div class="relative flex-col items-start m-auto align-middle">
            <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-24">
              <div class="relative items-center gap-12 m-auto lg:inline-flex">
                <div class="max-w-xl text-center lg:text-left">
                  <div>
                    <p class="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                      SmartchaosLab - Revolutionizing Application Reliability
                      with Chaos Engineering
                    </p>
                    <p class="max-w-xl mt-4 text-base lg:text-lg tracking-tight text-gray-700">
                      SmartchaosLab is a cutting-edge platform that brings chaos
                      engineering to the forefront of application reliability
                      improvement. By intelligently simulating controlled
                      failures in cloud environments, SmartchaosLab empowers
                      organizations to identify weaknesses, enhance system
                      resilience, and optimize application performance.
                    </p>
                  </div>
                  <div class="flex justify-center gap-3 mt-10 lg:justify-start">
                    <a
                      class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600"
                      
                    >
                    <Link to="/smartchaoslab-revolutionizing-application-reliability-with-chaos-engineering"><a> <span> Read more → </span></a></Link>  
                    </a>
                  </div>
                </div>
              </div>
              <div class="order-first block w-full mt-5  lg:mt-0 lg:order-first">
                <img
                  class="object-cover object-center w-full h-[300px] md:h-[400px] lg:h-[500px] mx-auto bg-gray-300 lg:ml-auto"
                  alt="hero"
                  src={laptop_img}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
