import React from 'react'
import { Link } from 'react-router-dom'
import mslogo_img from '../../../assets/img/Products/View_All_Products/ms360-logo-1.svg'
import smart_img from "../../../assets/img/Products/SmartChaosLab/smartchaoslab-only-image-logo.png"
import infravue_img from '../../../assets/img/Products/InfraVue/infra-vue-image-only-logo.png'
import Sys_img from '../../../assets/img/Products/View_All_Products/sysar-logo.svg'
import aeroai_img from '../../../assets/img/Products/AeroPerformai/aeroperform_logo.svg'
import iiot_img from "../../../assets/img/iiot/aero_edge_iiot.jpg"

export default function MainSection1() {
  return (
    <div>
        <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-5 mx-auto">
        <h1 class="text-2xl text-center font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">explore our <br/> awesome <span class="underline decoration-blue-500">Products</span></h1>



        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-2">
            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">
                <span class="inline-block text-[#252f80] dark:text-blue-400">
                   <img className=' w-12' src={mslogo_img}></img>
                </span>

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Maturity Spectrum 360</h1>

                <p class="text-gray-500 dark:text-gray-300">
                Comprehensive, AI driven framework that assesses organization's maturity level, pinpoints growth opportunities, and charts a course for success.


                </p>

                <Link to="/maturity-spectrum-360"> <a  class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </a></Link>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">
                <span class="inline-block text-[#252f80] dark:text-blue-400">
                  <img className=' w-12' src={smart_img}></img>
                </span>

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">SmartChaos Lab</h1>

                <p class="text-gray-500 dark:text-gray-300">
                AI based Chaos Engineering Tool that automatically identifies weaknesses and potential outages in infrastructures by inducing chaos tests and provides resolutions.
                </p>

                <Link to="/smartchaos-lab">    <a  class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </a></Link>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">
                <span class="inline-block text-blue-500 dark:text-blue-400">
                    <img className=' w-16' src={aeroai_img}></img>
                </span>

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">AeroPulse AI</h1>

                <p class="text-gray-500 dark:text-gray-300">
                A next generation cloud performance management offering a comprehensive solution for monitoring, predicting, and optimizing the performance of your cloud-hosted applications and systems.
                </p>

                <Link to="/aeropulse-ai">  <a  class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </a></Link>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-[#252f80] rounded-xl">
                <span class="inline-block text-[#252f80] dark:text-blue-400">
                   <img className=' w-12' src={infravue_img}></img>
                </span>

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">AeroInfra Vue</h1>

                <p class="text-gray-500 dark:text-gray-300">
                Zero-code approach to simplifying the intricate procedures involved in infrastructure design and deployment.
                </p>

                <Link to="/infra-vue">     <a  class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </a></Link>
            </div>

            <div class="p-8 bg-slate-100 space-y-3  border-2 border-blue-200 dark:border-blue-300 rounded-xl">
                <span class="inline-block text-blue-500 dark:text-blue-400">
                    <img className=' w-12' src={Sys_img}></img>
                </span>

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">AeroSys AR</h1>

                <p class="text-gray-500 dark:text-gray-300">
                System Analysis and Remediation, an intuitive tool that allows users to visually interact with real-time system components.
                </p>

                <Link to="/sys-ar">  <a  class="inline-flex p-2 mt-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </a></Link>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">
                <span class="inline-block text-[#252f80] dark:text-blue-400">
                   <img className=' w-12' src={iiot_img}></img>
                </span>

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">AeroEdge IIOT</h1>

                <p class="text-gray-500 dark:text-gray-300">
                AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration.

                </p>

                <Link to="/aeroedge-iiot"> <a  class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </a></Link>
            </div>      
        </div>
    </div>
</section>
    </div>
  )
}
