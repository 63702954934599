import React from 'react'
import img1 from '../../assets/img/Investor_relations/img1.jpg'
import img2 from '../../assets/img/Investor_relations/img2.jpg'
export default function OtherSection() {
  return (
    <div>
       <div class="relative group">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img1}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-4xl lg:text-6xl font-bold transition duration-300  group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center my-20 md:my-40 lg:my-36'>Overview</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Welcome to AeroAegis's Investor Relations, your gateway to exploring the exciting investment opportunities we offer. As a pioneering IT solutions company, we are at the forefront of innovation, driving digital transformation, and reshaping business realities. Our commitment to excellence and our track record of delivering tangible value set us apart in the industry. We invite you to discover the potential of investing in AeroAegis and being part of our journey towards technological advancement and growth.
    </p>
  </div>
</div>
        <section class=" mt-2 ">
        <div class="relative group">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img2}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-4xl lg:text-6xl font-bold transition duration-300  group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center  my-24 md:my-40 lg:my-36'>Investing in AeroAegis</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition duration-700  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Investing in AeroAegis presents a unique opportunity to become a part of a dynamic company with a vision to enhance IT capabilities across industries. By joining hands with us, you are investing in a company that understands the evolving landscape of digital transformation and is well-equipped to provide impactful solutions. As we continue to expand our suite of products and services, your investment can play a crucial role in accelerating our growth, advancing innovation, and maximizing returns.
    </p>
  </div>
</div>
        </section>
      
    </div>
  )
}
