import React from "react";
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import PartnershipSection from "./PartnershipSection";
import PatnersList from "./PatnersList";
import Section1 from "./Section1";
import Section2 from "./Section2";
import LastSection from "./LastSection";
import Section3 from "./Section3";

export default function index() {
  return (
    <div>
      <PartnershipSection />
      <PatnersList />
      <AnimationRevealPage>
        <Section1 />
        <Section2 />
        <Section3 />
        <LastSection />
      </AnimationRevealPage>
    </div>
  );
}
