import React from 'react'
import hero_img from '../../assets/img/Whitepapers/blueprint.jpg'
import pdf3 from '../../assets/pdf/InfraVue.pdf'
export default function whitepaper3() {
  return (
    <div>
           <div className="relative flex mb-10 flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
    <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
      <svg
        className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
        viewBox="0 0 100 100"
        fill="currentColor"
        preserveAspectRatio="none slice"
      >
        <path d="M50 0H100L50 100H0L50 0Z" />
      </svg>
      <img
        className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
        src={hero_img}
        alt="hero"
      />
    </div>
    <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
      <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
        <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
        <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        InfraVue: Blueprinting Tomorrow's Infrastructure Today.
        </h2>
       
      </div>
    </div>
  </div>
  <div className="py-10 flex justify-center bg-[#fafafa]">
        <div className="w-full max-w-3xl">
          <iframe
            src={`${pdf3}#toolbar=0&navpanes=0&scrollbar=0`}
            title="Maturity Spectrum 360"
            width="100%"
            height="600"
            className="border border-gray-300 "
          />
        </div>
      </div>
    </div>
  )
}
