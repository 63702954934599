import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";

import AboutSection from "./AboutSection";
import WhatWeOffer from "./WhatweOffer";
import WhatsetsUsApart from "./WhatsetsUsApart";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Testimonials from "./Testimonials";
import Herosection3 from "./herosection3";
import Innovation from "./Innovation";
import { PortableWifiOffOutlined } from "@mui/icons-material";
import PoweredbyAero from "./PoweredbyAero";
import Products from "./Products";
export default function Homepage() {
  return (
    <div>
      <AnimationRevealPage>
        <Herosection3 />
        <AboutSection />

        <WhatsetsUsApart />
        <Products />
        <Innovation />

        <WhatWeOffer />
        <Section5 />

        <Section6 />
        <PoweredbyAero />
        <Testimonials />
      </AnimationRevealPage>
    </div>
  );
}
