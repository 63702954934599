import React from "react";
import data_img from "../../../assets/img/Consulting/workflow.jpg";
export default function MainSection3() {
  return (
    <div>
      <section className="">
        <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={data_img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96"
              />
            </div>
          </div>
          <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
          <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Business Workflow Automation</h3>
				<p className="mt-3 text-lg dark:text-gray-400">
        Beyond RPA, we specialize in automating end-to-end business workflows, integrating diverse
systems and applications to create streamlined, efficient operations. Our solutions encompass.
        </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Workflow Design and Optimization</h4>
							<p className="mt-2 dark:text-gray-400">
              Crafting tailored workflows that optimize business
processes, ensuring they are more efficient, scalable, and adaptable to change.
              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Integration of Disparate Systems</h4>
							<p className="mt-2 dark:text-gray-400">
              Utilizing APIs and custom integration solutions, we
connect various systems and platforms, enabling them to communicate and work
together seamlessly. This holistic integration approach ensures that data flows
smoothly across the organization, supporting real-time decision-making and
operational agility.

              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Automation of Decision Processes</h4>
							<p className="mt-2 dark:text-gray-400">
              Implementing decision automation tools that help
in quickly processing information, applying rules, and making informed decisions
without human intervention, thereby increasing the speed and accuracy of business
operations.
              </p>
						</div>
					</div>
				</div>
          </div>
        </div>
      </section>
    </div>
  );
}
