import React from 'react'
import main_section_img from "../../../assets/img/Products/SmartChaosLab/paralaz.jpg"

export default function MainSection1() {
  return (
    <div>
         <section class=" mt-5 px-4  pt-20 pb-20 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
            style={{
              backgroundImage: `url(${main_section_img})`,
            }}
          ></div>
          <div className="max-w-2xl mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            SmartChaosLab, offered by AeroAegis, stands out with its unique integration of game theory principles and advanced machine learning algorithms. This innovative approach not only enhances system resilience but also ensures dynamic, intelligent adaptation to the ever-evolving cloud environment.
            </p>
          </div>
        </section>
          
    </div>
  )
}
