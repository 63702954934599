import React from 'react'
import hero_img from '../../assets/img/termsConditions_hero.jpg'
export default function TnCContent() {
  return (
    <div>
      <div className="relative flex mb-10 flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
    <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
      <svg
        className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
        viewBox="0 0 100 100"
        fill="currentColor"
        preserveAspectRatio="none slice"
      >
        <path d="M50 0H100L50 100H0L50 0Z" />
      </svg>
      <img
        className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
        src={hero_img}
        alt="hero"
      />
    </div>
    <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
      <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
        <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
        <h2 className="mb-5 lg:text-5xl md:text-3xl   font-bold tracking-tight text-gray-900 text-4xl leading-none">
        Terms & Conditions
        </h2>
        
      </div>
    </div>
  </div>
   
<hr className="mb-5 border-gray-300" />

<div className=' px-5 lg:px-20 py-5'>
<p class="mb-3 text-gray-700 text-lg dark:text-gray-400">The use of any product, service or feature (the "Materials") available through the internet websites accessible at aeroaegis.com by any user of the Website (“User” or "You" or "Your" hereafter) shall be governed by the following terms of use. The following terms and conditions will be deemed to have been accepted by the User on usage of the Website. You are requested to read them carefully before you use the services of this site.

</p>
<ul className=' list-disc px-10'>
<li><p class="mb-3 text-gray-700 text-lg dark:text-gray-400">You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. AeroAegis reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Website. AeroAegis may make any other changes to the Website, the materials and the products, programs, services or prices (if any) described in the Website at any time without notice. </p></li>
<li><p class="mb-3 text-gray-700 text-lg dark:text-gray-400">This website is provided by AeroAegis Private Limited (hereinafter referred to as "AeroAegis" ), a company incorporated under the Companies Act, 1956 of India, having its registered office at BEML Layout, R.R.Nagar, Bangalore - 560098, India and shall be used for informational purposes only. By using the Website or downloading Materials from the Website, You hereby agree to abide by the terms and conditions set forth in this Terms of Use. In the event of You not agreeing to these terms and conditions, You are requested by AeroAegis not to use the Website or download Materials from the Website. If there is a conflict between the Terms of Use and terms of use posted for a specific area of the Website, the latter shall have precedence with respect to your use of that area of the Website.</p></li>
<li><p class="mb-3 text-gray-700 text-lg dark:text-gray-400">This Website, including all Materials present (excluding any applicable third party materials), is the property of AeroAegis and AeroAegis retains all rights, title or interest, including all intellectual property laws in such Materials.</p></li>
<li><p class="mb-3 text-gray-700  text-lg dark:text-gray-400">AeroAegis has business relationships with thousands of customers, suppliers, governments, and others. For convenience and simplicity, words like joint venture, partnership, and partner are used to indicate business relationships involving common activities and interests, and those words may not indicate precise legal relationships. </p></li>
</ul>
</div>

  
</div>
  )
}
