import React from 'react'
import final_img from "../../../assets/img/Products/Sys-AR/pexels-francesco-ungaro-2760615.jpg"
export default function FinalQuote() {
  return (
    <div>
          <section class=" mt-5 px-4  pt-20 pb-20 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
            style={{
              backgroundImage: `url(${final_img})`,
            }}
          ></div>
          <div className="max-w-2xl mb-5 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            As we navigate the dynamic IT landscape, Maturity Spectrum 360 stands at the forefront of change. With a future-oriented vision, we delve into the role of AI, data analytics, and automation in shaping the path to digital maturity. Maturity Spectrum 360 is not just a tool, but a strategic partner that understands their unique needs and paves the way for a successful digital transformation.
            </p>
          </div>
        </section>
    </div>
  )
}
