import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import MainSection from "./MainSection"
import MainSection1 from "./MainSection1"
import MainSection2 from "./MainSection2"
import MainSection3 from "./MainSection3"
import MainSection4 from "./MainSection4"

import FooterCard from "./FooterCard"
import HeroSection from './HeroSection';
import Commitment from '../Blockchain Integration/Commitment';
import Content1 from './Content1';
import MainSection5 from './MainSection5';
import MainSection6 from './MainSection6';

export default function index() {
  return (
    <div>
      <HeroSection/>
      <Content1/>
      <AnimationRevealPage>

        <MainSection1/>
   
        <MainSection/>
        <MainSection2/>
        <MainSection3/>
        <MainSection4/>
        <MainSection5/>
        <MainSection6/>
       {/* <Commitment/> */}
        <FooterCard/>
      </AnimationRevealPage>
    </div>
  )
}
