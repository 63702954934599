import React from "react";
import measure_vid from "../../../assets/videos/Devops/measure.mp4";
import align_vid from "../../../assets/videos/Devops/align.mp4";
import goal_vid from "../../../assets/videos/Devops/common-goal.mp4";
export default function MainSection1() {
  return (
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">What We Do</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-[#23307f] scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-lg">
            "We Establish, Inculcate and Preach industry standard Devops tools
            and practices to drive businesses."
          </p>
        </div>
        <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={measure_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex text-center mt-10 lg:mt-28 justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Measuring</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">
                  Measuring
                </p>
                <p className="text-base tracking-wide text-gray-300">
                  Discuss, decide and finalize the measuring points to be
                  enabled depending on the nature of the business application.
                </p>
              </div>
            </div>
          </a>
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={align_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex text-center mt-10 lg:mt-28 justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Aligning</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">Aligning</p>
                <p className="text-base tracking-wide text-gray-300">
                  Align stakeholders by setting SLOs for developers and
                  operations thus ensuring a shared sense of ownership and lazer
                  focus on a common goal on reliability thus balancing focus
                  between reliability and releases.
                </p>
              </div>
            </div>
          </a>
          <a aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={goal_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex text-center mt-10 lg:mt-28 justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Implementing</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">
                  Implementing
                </p>
                <p className="text-base tracking-wide text-gray-300">
                  Work towards the goal all the while open to lending time for
                  reducing toil by eliminating manual work, automate runbooks
                  and root cause annalysis all the while working on the common
                  goal.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
