import React from "react";
import img from "../../../assets/img/SREDvops/automation.jpg";
export default function MainSection2() {
  return (
    <div>
      <section className="dark:bg-gray-800 dark:text-gray-100">
        <span class="mb-10 block text-center text-2xl font-bold text-[#23307F]">
          Core Offerings
          <div>
            <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black"></p>
          </div>
        </span>

        <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
            <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
              Automation for Efficiency
            </h3>
            <p className="mt-3 text-lg dark:text-gray-400">
              At the heart of our approach is automation. By automating routine
              tasks, we significantly reduce the potential for human error,
              freeing up valuable resources to focus on more strategic
              initiatives. Automation extends across our product suite, from
              deploying updates with AeroSys AR to streamlining performance
              management with AeroPulse AI.
            </p>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={img}
                alt="marketing"
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
