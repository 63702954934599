import React from 'react'
import other1_img from '../../assets/img/Csr/other1.jpg'
import other2_img from '../../assets/img/Csr/other2.jpg'
import other3_img from '../../assets/img/Csr/other3.jpg'
export default function OtherSection9() {
  return (
    <div>
         <div class="relative group mt-4">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={other1_img}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full  text-center transform -translate-x-1/2 -translate-y-1/2 text-white  font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36'>Our Commitment to Sustainability</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Aero Aegis Inc. is committed to sustainability and reducing our environmental impact. We are actively exploring ways to incorporate eco-friendly practices and materials into our products and services.
<br/>
<span className=''>We are dedicated to staying informed about and adopting best practices in sustainability and environmental responsibility.</span>
    </p>
  </div>
</div>
<div class="relative group mt-4">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={other2_img}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full text-center transform -translate-x-1/2 -translate-y-1/2 text-white font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36'>Accountability & Transparency</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    We are committed to regularly reporting on our CSR initiatives and progress. We believe in transparency and being held accountable for our actions.
<br/>
<span className=''>We welcome feedback from stakeholders, including employees, clients, and the community, to continuously improve our CSR efforts.</span>
    </p>
  </div>
</div>
<div class="relative group mt-4">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={other3_img}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full text-center transform -translate-x-1/2 -translate-y-1/2 text-white  font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-16 md:my-36 lg:my-36'> Together Towards a Better Future</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center py-5 text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    At Aero Aegis Inc., we view CSR as an ongoing journey toward a better and more sustainable future. We recognize the importance of our role in creating positive social and environmental change, and we are dedicated to making a meaningful difference in the world we share. Together, we can build a brighter tomorrow.


    </p>
  </div>
</div>
    </div>
  )
}
