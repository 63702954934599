import React from 'react'
import img1 from '../../../assets/img/Products/MS360/pexels-rostislav-uzunov-5011647.jpg'
export default function MainSection1() {
  return (
    <div>
         <section class=" mt-5  pt-20 pb-20 px-4  relative">
          <div
            class="absolute  w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat bg-fixed"
            style={{
              backgroundImage:`url(${img1})`,
            }}
          ></div>
          <div className="max-w-4xl  mb-10 md:mx-auto text-center   lg:max-w-[62rem] md:mb-12">
            <p className="relative   tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            Maturity Spectrum 360 is cloud-ready and can be used for self or AI-enabled assessments across various domains such as chaos, SRE, DevOps, security, cloud, etc. The platform's design is guided by the need for a comprehensive, customizable, and user-friendly tool for assessing an organization's maturity level and guiding its improvement efforts.
            </p>
          </div>
        </section>
          
    </div>
  )
}
