import React from 'react'
import { Link } from 'react-router-dom'
import mslogo_img from '../../assets/img/Products/View_All_Products/ms360-logo-1.svg'
import smart_img from "../../assets/img/Products/SmartChaosLab/smartchaoslab-only-image-logo.png"
import infravue_img from '../../assets/img/Products/InfraVue/infra-vue-image-only-logo.png'
import Sys_img from '../../assets/img/Products/View_All_Products/sysar-logo.svg'
import aeroai_img from '../../assets/img/Products/AeroPerformai/aeroperform_logo.svg'
import aeroedge_img from '../../assets/img/iiot/aero_edge_iiot.jpg'
export default function Products() {
  return (
    <div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-blue-800">
          Our Premier Products
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="ea469ae8-e6ec-4aca-8875-fc402da4d16e"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#ea469ae8-e6ec-4aca-8875-fc402da4d16e)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative ml-1">Pioneering</span>
          </span>{' '}
          Products for Digital Transformation
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
        At AeroAegis, we are in the business of innovation, primarily through our suite of groundbreaking
products designed to revolutionize how organizations navigate the digital landscape. Our
mission is to equip businesses with the tools and technologies they need to thrive in an era of
constant change. From enhancing operational efficiency to unlocking new realms of possibility,
our products stand at the forefront of digital transformation.
        </p>
      </div>
      <div className="flex flex-wrap justify-center gap-8  row-gap-10 ">
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img className=' w-12 lg:w-16'  src={mslogo_img}></img>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">Maturity Spectrum 360</h6>
          <p className="mb-3 text-base  text-gray-700  lg:text-lg">
          A comprehensive platform that provides organizations with a
clear roadmap for digital transformation, identifying growth opportunities and areas for
improvement.

          </p>
        <Link to="/maturity-spectrum-360">  <a
           
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-400 hover:text-blue-900"
          >
            Learn more
          </a></Link>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img className=' w-14 lg:w-20'  src={smart_img}></img>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">SmartChaos Lab</h6>
          <p className="mb-3 text-base  text-gray-700  lg:text-lg">
          A cutting-edge solution for ensuring cloud resilience, integrating
advanced machine learning and game theory to protect and optimize cloud
infrastructures.
          </p>
          <Link to="/smartchaos-lab">  <a
         
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-400 hover:text-blue-900"
          >
            Learn more
          </a></Link>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img className=' w-14 lg:w-20'  src={aeroai_img}></img>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">AeroPulse AI</h6>
          <p className="mb-3 text-base  text-gray-700  lg:text-lg">
          A next generation cloud performance management offering a comprehensive solution for monitoring, predicting, and optimizing the performance of your cloud-hosted applications and systems.
          </p>
          <Link to="/aeropulse-ai">  <a
            
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-400 hover:text-blue-900"
          >
            Learn more
          </a></Link>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img className=' w-12 lg:w-14'  src={infravue_img}></img>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">
          AeroInfra Vue
          </h6>
          <p className="mb-3 text-base  text-gray-700  lg:text-lg">
          A zero-code platform that simplifies the complexity of infrastructure
design and deployment, making it accessible to businesses of all sizes.
          </p>
          <Link to="/infra-vue"> <a
           
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-400 hover:text-blue-900"
          >
            Learn more
          </a></Link>
        </div>
        <div className="max-w-md mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img className=' w-12 lg:w-14'  src={Sys_img}></img>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">
          AeroSys AR
          </h6>
          <p className="mb-3 text-base  text-gray-700  lg:text-lg">
          An innovative application that leverages augmented reality for real-world
system analysis, defect identification, and automated repair, enhancing system
management processes.
          </p>
          <Link to="/sys-ar"> <a
            
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-400 hover:text-blue-900"
          >
            Learn more
          </a></Link>
        </div>
        <div className="max-w-md mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img className=' w-12 lg:w-14'  src={aeroedge_img}></img>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5">
          AeroEdge IIoT
          </h6>
          <p className="mb-3 text-base  text-gray-700  lg:text-lg">
          AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration, thus empowering industries.
          </p>
          <Link to="/aeroedge-iiot"> <a

            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-400 hover:text-blue-900"
          >
            Learn more
          </a></Link>
        </div>
      </div>
    </div>
    </div>
  )
}
