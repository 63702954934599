import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import MainSection1 from './Mainsection1';
import HeadSection from './Headsection'
import MainSection from './MainSection'
import MainSection2 from './MainSection2'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'
import MainSection5 from './MainSection5';
import FinalSection from './FinalSection'
import Content1 from './Content1';


export default function index() {
  return (
    <div>
        <HeadSection/>
        <Content1/>
        <AnimationRevealPage>
        <MainSection1/>
        <MainSection/>
        <MainSection2/>
        <MainSection3/>
        
        <FinalSection/>
        </AnimationRevealPage>
    </div>
  )
}
