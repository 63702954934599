import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import Herosection from './Herosection'
import MainSection1 from './MainSection1'
import MainSection2 from './MainSection2'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'
import MainSection5 from './MainSection5'
import FinalHeader from './FinalHeader'
import MainSection6 from './MainSection6';
import MainSection7 from './MainSection7';
import Content1 from './Content1';
import Commitment from './Commitment';

export default function index() {
  return (
    <div>
        <Herosection/>
        <Content1/>
        <MainSection1/>
        <Commitment/>
        <AnimationRevealPage>
        <MainSection2/>
        <MainSection3/>
        <MainSection4/>
        <MainSection5/>
        <MainSection6/>
        <MainSection7/>
        <FinalHeader/>
        </AnimationRevealPage>
    </div>
  )
}
