import React from 'react'

export default function Content1() {
  return (
    <div className=' py-10 px-5'>
      <div class="max-w-4xl mx-auto bg-[#23307f] shadow-blue-800 shadow-2xl rounded-xl">
        <div className=' px-5 py-5'>
      <p className="text-base text-center text-white md:text-lg">
      At AeroAegis, our commitment to leveraging cutting-edge technologies for the advancement of
your business operations is paramount. In line with this, our Blockchain Solution is designed to
enhance the security, transparency, and efficiency of digital processes across your organization.
Integrating blockchain technology with our product suite enriches our offerings with
decentralized applications (DApps), smart contracts, and distributed ledger technologies,
creating a robust infrastructure that is secure, transparent, and immutable.


       
        </p></div>
        </div>
    </div>
  )
}
