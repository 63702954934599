import React from 'react'
import mlmodel_vid from '../../../assets/videos/AiML/model.mp4'
import vid1 from '../../../assets/videos/AiML/vid1.mp4'
import vid3 from '../../../assets/videos/AiML/nlp.mp4'
import vid4 from '../../../assets/videos/AiML/lvm.mp4'

export default function SectionOne() {
  return (
    <div>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-2">
          What We Do
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-[#23307f] scale-x-30 group-hover:scale-x-100" />
        </h2>
        <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md">
        "Embracing the Power of Artificial Intelligence and Machine Learning <br/>to Improve the Lives of the People."
        </p>
      </div>
  <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
    <a  aria-label="View Item">
      <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
      <video  muted
                  loop autoPlay
              className="object-cover w-full h-[450px] md:h-[450px]  xl:h-[450px]"
              src={vid1}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex text-center mt-40 md:text-center md:mt-40 lg:text-center xl:text-center xl:mt-40 justify-center font-semibold align-content-center text-2xl group-hover:hidden'>
          Large Language Models (LLMs) and Maturity Spectrum 360</h1>
        </div>  
        <div className="absolute inset-0 px-6 py-5 lg:py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100">
          <p className="mb-2 md:text-base text-base lg:text-[12px] xl:text-base font-bold text-gray-100">Large Language Models (LLMs) and Maturity Spectrum 360</p>
          <p className="text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          The Spectra Insight Core Engine, a pivotal component of Maturity Spectrum 360, leverages
LLMs to understand and analyze the vast and complex datasets related to organizational
maturity assessments.
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          This engine processes natural language data from a variety of
sources, interpreting the nuances and contexts of business operations, to provide tailormade recommendations. 
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          LLMs enable the engine to generate insights that are not only
deeply relevant but also actionable, allowing businesses to navigate their digital
transformation journey with precision and insight.
          </p>
        </div>
      </div>
    </a>

    

    <a  aria-label="View Item">
      <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
      <video  muted
                  loop autoPlay
              className="object-cover w-full h-[450px] md:h-[450px] xl:h-[450px]"
              src={vid3}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex md:text-center mt-40 text-center md:mt-40 lg:text-center xl:text-center xl:mt-40 justify-center font-semibold align-content-center text-2xl group-hover:hidden'>Natural Language Processing (NLP)</h1>
        </div> 
        <div className="absolute inset-0 px-6 py-5 lg:py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          <p className="mb-2 md:text-base text-base lg:text-[12px] xl:text-base font-bold text-gray-100">Natural Language Processing (NLP)</p>
          <p className="text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          NLP plays a crucial role in enhancing the interpretive capabilities of our AI & ML Solutions.
By applying NLP, our products, including Maturity Spectrum 360, can efficiently process,
understand, and analyze human language data, extracting meaningful patterns and
insights.
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          This capability is essential for tasks such as analyzing feedback, parsing complex
technical documents, and facilitating seamless interactions between users and our digital
platforms. 
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          NLP enables our products to offer intuitive and human-like interactions, making
complex data accessible and understandable to decision-makers.

          </p>
        </div>
      </div>
    </a>

    <a  aria-label="View Item">
      <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
      <video  muted
                  loop autoPlay
              className="object-cover w-full h-[450px] md:h-[450px] xl:h-[450px]"
              src={vid4}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-40 text-center md:text-center md:mt-40 lg:text-center xl:text-center xl:mt-40 justify-center font-semibold align-content-center text-2xl group-hover:hidden'>Latent Variable Models (LVM)</h1>
        </div> 
        <div className="absolute inset-0 px-6 py-5 lg:py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          <p className="mb-2 md:text-base text-base lg:text-[12px] xl:text-base font-bold text-gray-100">Latent Variable Models (LVM)</p>
          <p className="text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          LVMs are utilized within our AI & ML Solutions to uncover hidden patterns in data, providing
a deeper understanding of the underlying structures and relationships within
organizational processes.
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          In the context of Maturity Spectrum 360, LVMs aid in identifying
latent factors that influence an organization's maturity level, offering insights into areas
that may not be immediately apparent through traditional analysis methods.
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          This approach
allows for a more nuanced assessment of organizational strengths and weaknesses,
enabling targeted improvements that directly impact business outcomes.

          </p>
        </div>
      </div>
    </a>
  </div>
</div>
</div>
  )
}