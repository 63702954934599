import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import HeroSection from './HeroSection'
import MainSection2 from './MainSection2'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'
import MainSection5 from './MainSection5'
import MainSection6 from './MainSection6'
import MainSection7 from './MainSection7'
import MainSection8 from './MainSection8'
import MainSection9 from './MainSection9'
import FinalSection from './FinalSection'
import MainSection10 from './MainSection10';


export default function index() {
  return (
    <div>
        <HeroSection/>
        <AnimationRevealPage>
        <MainSection2/>
        <MainSection3/>
        <MainSection4/>
        <MainSection5/>
        <MainSection6/>
        <MainSection7/>
        <MainSection8/>
        <MainSection9/>
        <MainSection10/>
      
        <FinalSection/>
        </AnimationRevealPage>
    </div>
  )
}
