import React from 'react'
import final_img from "../../../assets/img/Products/SmartChaosLab/bulb.jpg"
export default function FinalQuote() {
  return (
    <div>
          <section class=" mt-5 px-4 py-8 lg:py-10 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
            style={{
              backgroundImage: `url(${final_img})`,
            }}
          ></div>
          <div className="max-w-2xl mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            SmartChaosLab is not just a tool, it's a comprehensive solution for businesses aiming to stay ahead in the rapidly evolving landscape of cloud computing. With its intelligent, adaptive approach to system resilience, SmartChaosLab stands as a beacon of innovation, ensuring that cloud infrastructures are not only robust but also smartly equipped to face the challenges of tomorrow.
            </p>
          </div>
        </section>
    </div>
  )
}
