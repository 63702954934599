import React from "react";
import cycles_img from "../../../assets/img/SREDvops/cycles.jpg";
export default function MainSection3() {
  return (
    <div>
      <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
        <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
          <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
            <img
              src={cycles_img}
              alt=""
              className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96"
            />
          </div>
        </div>
        <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
          <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
            Continuous Integration and Delivery (CI/CD)
          </h3>
          <p className="mt-3 text-lg dark:text-gray-400">
            Our products are designed to support CI/CD practices, enabling
            faster release cycles and more reliable software deployments. By
            facilitating a smoother integration of new features and quick
            rectification of issues, we help businesses stay agile and
            responsive to market demands.
          </p>
        </div>
      </div>
    </div>
  );
}
