import React from 'react'
import hands_img from '../../assets/img/Csr/community.jpg'
export default function Section2() {
  return (
    <div>
             <section className="dark:bg-gray-800 pt-5 dark:text-gray-100">
            <div className="container flex flex-col mx-auto lg:flex-row">
              <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
                <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                  <img
                    src={hands_img}
                    alt=""
                    className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96"
                  />
                </div>
              </div>
              <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
                <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
                Community Engagement
                </h3>
                <div className="flex space-x-2 sm:space-x-4 pt-12">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    ></path>
                  </svg>
                  <div className="space-y-2">
                    <p className="text-lg font-medium leadi">
                    We actively engage with the communities where we operate. Our goal is to contribute to their well-being by supporting local initiatives, educational programs, and charitable organizations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
    </div>
  )
}
