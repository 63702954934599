import React from 'react'
import img1 from '../../assets/img/HomePage/pexels-fox-1595388.jpg'
import img2 from '../../assets/img/HomePage/pexels-pranjal-srivastava-2403251.jpg'
export default function Innovation() {
  return (
    <div>
         <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid max-w-screen-lg gap-8 row-gap-5 md:row-gap-8 sm:mx-auto lg:grid-cols-2">
        <div className="transition duration-300 transform bg-white rounded shadow-sm hover:-translate-y-1 hover:shadow md:text-center">
          <div className="relative">
            <img
              className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-96"
              src={img1}
              alt=""
            />
            <div className="absolute inset-0 bg-gray-800 bg-opacity-25" />
          </div>
          <div className="px-6 py-8 border text-center border-t-0 rounded-b sm:px-8">
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            Innovation: Our Core Philosophy
            </h5>
            <p className="mb-5 text-base  text-gray-700  lg:text-lg text-justify">
            Our commitment to innovation transcends our product offerings, extending into specialized
services that amplify the efficacy of our solutions. Whether it's through the seamless
integration of <span className=' font-semibold text-[#252f80]'>AeroPulse AI</span> into your existing systems for unparalleled performance
management or harnessing <span className=' font-semibold text-[#252f80]'>AeroInfra Vue </span>for efficient infrastructure deployment, our team is
dedicated to ensuring your business not only keeps pace but leads in the digital era.

            </p>
            <div
             
              className="inline-flex items-center justify-center py-1 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-400 hover:bg-blue-900 focus:shadow-outline focus:outline-none"
            >
            
            </div>
          </div>
        </div>
        <div className="transition duration-300  transform bg-white rounded shadow-sm hover:-translate-y-1 hover:shadow md:text-center">
          <div className="relative">
            <img
              className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-96"
              src={img2}
              alt=""
            />
            <div className="absolute inset-0 bg-gray-800 bg-opacity-25" />
          </div>
          <div className="px-6 py-8 border text-center border-t-0 rounded-b sm:px-8">
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            How We Empower Your Business

            </h5>
            <p className="mb-12 text-base  text-gray-700  lg:text-lg text-justify">
            In the ever-accelerating race of digital transformation, AeroAegis shines as a beacon of
innovation, navigating businesses through the intricacies of modernization. Our products do
more than perform; they serve as the engines of change, empowering your enterprise to
overcome obstacles, minimize risks, and capitalize on the opportunities that lie within the
digital expanse.
            </p>
            <div
            
              className="inline-flex items-center  justify-center py-1  px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-400 hover:bg-blue-900 focus:shadow-outline focus:outline-none"
            >
            
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
