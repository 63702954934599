import React from 'react'
import services_img from '../../../assets/img/discover_aeroaegis/support.jpg'
export default function MainSection6() {
  return (
    <section className="">
          <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
            <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] ">
              <h3 className="text-2xl font-bold tracki sm:text-3xl ">
              Beyond Products: Comprehensive Support and Services
              </h3>
              <div className="flex space-x-2 sm:space-x-4 pt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                <div className="space-y-2">
                  <p className="text-lg font-medium leading">
                  AeroAegis extends its innovation beyond products to include a suite of comprehensive
services. From strategic consulting to implementation and beyond, our team is equipped to
support your digital transition at every step, offering insights, expertise, and assistance that
propel you towards your goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 xl:w-3/5 ">
              <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                <img
                  src={services_img}
                  className="rounded-lg shadow-lg  object-cover  h-80 sm:min-h-96"
                />
              </div>
            </div>
          </div>
        </section>
  )
}
