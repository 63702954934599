import React from 'react'
import setsusapart_img from "../../assets/img/HomePage/pexels-fauxels-3184360.jpg"

export default function WhatsetsUsApart() {
  return (
    <div className=" relative flex bg-gray-50 flex-col-reverse py-0 lg:py-16 lg:flex-col">
      <div className="w-full max-w-xl px-4 mx-auto md:px-0 lg:px-5 lg:py-20 lg:max-w-screen-xl">
        <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
          <h2 className="inline-block lg:ml-24 mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:text-center">
            What Sets Us Apart
            <div className="h-1 mt-1 ml-auto duration-300 origin-left transform bg-[#252f80] scale-x-30 group-hover:scale-x-100" />
          </h2>
          
          <p className="mb-5 text-base text-gray-700 md:text-lg md:text-justify">
          The essence of AeroAegis is encapsulated in our product suite meticulously crafted, AI-enhanced, and designed to address the evolving demands of today's businesses.
          </p>
          <p className="mb-5 text-base text-gray-700 md:text-lg md:text-justify">
          From 
refining organizational growth strategies with <span className=' font-semibold text-[#252f80]'>Maturity Spectrum 360</span> to bolstering cloud resilience with <span className=' font-semibold text-[#252f80]'>SmartChaos Lab</span>, our offerings represent the zenith of technological progress. 
          </p>
          <p className="mb-5 text-base text-gray-700 md:text-lg md:text-justify">
          Our unwavering dedication to product innovation ensures the delivery of transformative tools 
that elevate businesses to new heights of excellence.
          </p>
          
        </div>
      </div>
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
        <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={setsusapart_img}
          alt="img"
        />
      </div>
    </div>
  );
}
