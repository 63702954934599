import React from 'react'
import hero_img from "../../../assets/img/Products/View_All_Products/hero.png"

export default function HeroSection() {
  return (
    <div>
        <section class="bg-white dark:bg-gray-900">
    <div class="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-10">
        <div class="mr-auto place-self-center lg:col-span-7">
            <h1
                class="max-w-2xl mb-4 text-4xl text-[#223081] font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
                Products
            </h1>

            <p class="max-w-2xl mb-6  text-gray-700 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">At AeroAegis, our products are the strokes of innovation that create vibrant, transformative landscapes.
               
                
            </p>

        </div>

        <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={hero_img} alt="hero image"/>
        </div>

    </div>
</section>
    </div>
  )
}
