import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import bg_img from "../../assets/img/contactus_hero.jpg";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
const API_PATH = "https://aeroaegis.com/contact.php";

export default function Contact() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [captchaError, setCaptchaError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    loadCaptchaEnginge(6, "white");
    const interval = setInterval(() => {
      loadCaptchaEnginge(6);
    }, 60000);
    // Clear the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userCaptchaValue = e.target.captcha.value;
    // Validate the captcha
    if (!validateCaptcha(userCaptchaValue)) {
      setCaptchaError("Captcha Does Not Match");
      return;
    }
    // Reset the captcha value
    setCaptchaValue("");
    setCaptchaError("");
    setIsSubmitting(true);
    setShowSpinner(true);
    setIsOverlayVisible(true); // Show overlay

    try {
      const response = await axios.post(API_PATH, formData);
      if (response.status === 200) {
        setIsSuccessModalOpen(true);
        setFormData({
          user_name: "",
          user_email: "",
          user_message: "",
        });
        loadCaptchaEnginge(6);
        window.location.reload(); // Reload the page
      } else {
        console.error("Unsuccessful response:", response.status, response.data);
      }
    } catch (error) {
      console.error("error", error);
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received from server.");
      } else {
        console.error("Error during request setup:", error.message);
      }
      
    } finally {
      setIsSubmitting(false);
      setShowSpinner(false);
      setIsOverlayVisible(false); // Hide overlay after form submission
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
  };

  useEffect(() => {
    if (isSuccessModalOpen) {
      const timer = setTimeout(() => {
        setShowSpinner(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSuccessModalOpen]);

  return (
    <div>
      {isOverlayVisible && (
        <div
          className="fixed inset-0 z-20 bg-black opacity-50 cursor-not-allowed"
          style={{ pointerEvents: "auto" }}
        ></div>
      )}
      <section
        class="min-h-screen bg-cover  "
        style={{
          backgroundImage: `url(${bg_img})`,
        }}
      >
        <div class="flex flex-col min-h-screen bg-black/60">
          <div class="container flex flex-col flex-1 px-6 py-12 mx-auto">
            <div class="flex-1 lg:flex lg:items-center lg:-mx-6">
              <div class="text-white lg:w-1/2 lg:mx-12">
                <h1 class="text-xl font-semibold capitalize lg:text-6xl">
                  Contact Us
                </h1>

                <p className="text-lg lg:text-xl mt-6">
                  We are here to help. We'd love to hear from you! Whether you
                  have a question, a comment or just want to chat, you can reach
                  out to us by filling in the details.
                </p>
                <p class="max-w-xl text-lg lg:text-2xl mt-6">
                  If you have any query, Please feel free to contact us
                </p>

                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2 lg:grid-cols-2 mt-5">
                  <div>
                    <span class="inline-block p-3 text-[#24307f] rounded-full bg-blue-100/80 dark:bg-gray-800">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>
                    </span>

                    <h2 class="mt-4 text-base font-medium text-white ">
                      Email
                    </h2>
                    <p class="mt-2 text-sm cursor-pointer text-blue-500 dark:text-blue-400">
                      info@aeroaegis.com
                    </p>
                  </div>

                  <div>
                    <span class="inline-block p-3 text-[#24307f] rounded-full bg-blue-100/80 dark:bg-gray-800">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                        />
                      </svg>
                    </span>

                    <h2 class="mt-4 text-base font-medium text-white">Phone</h2>

                    <p class="mt-2 text-sm cursor-pointer text-blue-500 dark:text-blue-400">
                      +1 (302) 497-4435
                    </p>
                  </div>
                </div>
                <div class="mt-6 md:mt-8">
                  <h3 class="text-gray-300 ">Follow us</h3>

                  <div class="flex mt-4 -mx-1.5 ">
                    <a
                      class="mx-1.5 text-white transition-colors duration-300 transform hover:text-blue-500"
                      href="#"
                    >
                      <svg
                        class="w-10 h-10 fill-current"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M18.6668 6.67334C18.0002 7.00001 17.3468 7.13268 16.6668 7.33334C15.9195 6.49001 14.8115 6.44334 13.7468 6.84201C12.6822 7.24068 11.9848 8.21534 12.0002 9.33334V10C9.83683 10.0553 7.91016 9.07001 6.66683 7.33334C6.66683 7.33334 3.87883 12.2887 9.3335 14.6667C8.0855 15.498 6.84083 16.0587 5.3335 16C7.53883 17.202 9.94216 17.6153 12.0228 17.0113C14.4095 16.318 16.3708 14.5293 17.1235 11.85C17.348 11.0351 17.4595 10.1932 17.4548 9.34801C17.4535 9.18201 18.4615 7.50001 18.6668 6.67268V6.67334Z" />
                      </svg>
                    </a>

                    <a
                      class="mx-1.5 text-white transition-colors duration-300 transform hover:text-blue-500"
                      target="_blank"
                      href="https://www.linkedin.com/company/96893605"
                    >
                      <svg
                        class="w-8 h-8"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2 8.80005C16.4731 8.80005 17.694 9.30576 18.5941 10.2059C19.4943 11.1061 20 12.327 20 13.6V19.2H16.8V13.6C16.8 13.1757 16.6315 12.7687 16.3314 12.4687C16.0313 12.1686 15.6244 12 15.2 12C14.7757 12 14.3687 12.1686 14.0687 12.4687C13.7686 12.7687 13.6 13.1757 13.6 13.6V19.2H10.4V13.6C10.4 12.327 10.9057 11.1061 11.8059 10.2059C12.7061 9.30576 13.927 8.80005 15.2 8.80005Z"
                          fill="currentColor"
                        />
                        <path
                          d="M7.2 9.6001H4V19.2001H7.2V9.6001Z"
                          fill="currentColor"
                        />
                        <path
                          d="M5.6 7.2C6.48366 7.2 7.2 6.48366 7.2 5.6C7.2 4.71634 6.48366 4 5.6 4C4.71634 4 4 4.71634 4 5.6C4 6.48366 4.71634 7.2 5.6 7.2Z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>

                    <a
                      class="mx-1.5 text-white transition-colors duration-300 transform hover:text-blue-500"
                      href="#"
                    >
                      <svg
                        class="w-8 h-8"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 10.2222V13.7778H9.66667V20H13.2222V13.7778H15.8889L16.7778 10.2222H13.2222V8.44444C13.2222 8.2087 13.3159 7.9826 13.4826 7.81591C13.6493 7.64921 13.8754 7.55556 14.1111 7.55556H16.7778V4H14.1111C12.9324 4 11.8019 4.46825 10.9684 5.30175C10.1349 6.13524 9.66667 7.2657 9.66667 8.44444V10.2222H7Z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>

                    <a
                      class="mx-1.5 text-white transition-colors duration-300 transform hover:text-blue-500 h-8 mt-[3px]"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCZDzOYE4W-stKNR9AY2dUmg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-youtube h-8"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div class="mt-8 lg:w-1/2 lg:mx-6">
                <div class="w-full px-8 py-10 mx-auto overflow-hidden bg-white shadow-2xl rounded-xl dark:bg-gray-900 lg:max-w-xl">
                  <h1 class="text-xl font-medium text-gray-700 dark:text-gray-200">
                    Contact form
                  </h1>

                  <p class="mt-2 text-gray-500 dark:text-gray-400">
                    Ask us everything and we would love to hear from you
                  </p>

                  <form className="mt-4" method="post" onSubmit={handleSubmit}>
                    <div className=" md:grid lg:grid lg:grid-cols-2 gap-3">
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          name="user_name"
                          value={formData.user_name}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded-md border-slate-300"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Your Email
                        </label>
                        <input
                          type="email"
                          name="user_email"
                          value={formData.user_email}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded-md border-slate-300"
                          placeholder="Your Email"
                          required
                        />
                      </div>
                      <div className="col-span-2 mb-4">
                        <label
                          htmlFor="message"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Message
                        </label>
                        <textarea
                          name="user_message"
                          value={formData.user_message}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded-md border-slate-300"
                          placeholder="Leave a message here"
                          style={{ height: "120px" }}
                          required
                        ></textarea>
                      </div>
                      <div className=" py-2 col-span-2 mb-4">
                        <div className=" flex items-center">
                          <LoadCanvasTemplateNoReload />
                          <IconButton
                            aria-label="Reload Captcha"
                            size="small"
                            color="primary"
                            onClick={() => loadCaptchaEnginge(6, "white")}
                          >
                            <RefreshIcon />
                          </IconButton>
                        </div>
                        <input
                          type="text"
                          name="captcha"
                          value={captchaValue}
                          onChange={(e) => setCaptchaValue(e.target.value)}
                          className=" mt-2 w-40 border rounded-md border-slate-300"
                          placeholder="Enter Captcha"
                          required
                        />
                        {captchaError && (
                          <p className="text-red-500 text-sm mt-1">
                            {captchaError}
                          </p>
                        )}
                      </div>

                      <div className="col-span-2">
                        <button
                          className="btn bg-[#252f80] text-white hover:bg-[#2d3da5] rounded-pill py-3 px-5"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {showSpinner ? (
                            <div className="h-5 w-5 animate-spin rounded-full border-t-4 border-blue-600 mx-auto" />
                          ) : (
                            "Send Message"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Transition appear show={isSuccessModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen flex items-center justify-center">
            <Dialog.Overlay className="fixed inset-0  " />

            <div className="inline-block align-middle p-8 bg-slate-50 rounded-lg text-left">
              <Dialog.Title className="text-lg md:text-3xl lg:text-4xl font-bold mb-4">
                Thank you for contacting us.
              </Dialog.Title>
              <p className="text-gray-700 mb-4 text-center text-base md:text-xl lg:text-xl">
                We will get back to you shortly.
              </p>

              <div className="mt-4 flex justify-center">
                <button
                  onClick={closeModal}
                  className="bg-[#252f80] text-white px-4 py-2 rounded-lg cursor-pointer"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
