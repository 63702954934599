import React from 'react'
import hero_img from '../../../assets/img/iiot/Wavy_Tech-26_Single-05.jpg'
import ms360_img from "../../../assets/img/iiot/aero_edge_iiot.jpg"
export default function HeroSection() {
  return (
    <div>
      <section className="relative flex flex-col items-center w-full bg-white">
        <div className="w-full px-5 py-8 mx-auto lg:px-16 lg:py-10 max-w-7xl md:px-12">
          <div className="grid gap-4 lg:flex lg:flex-row-reverse lg:gap-24 lg:grid-cols-2">
            <div className=" w-full flex justify-center md:justify-center md:flex lg:mt-0 lg:hidden">
              <div className="flex items-center">
                <img
                  src={ms360_img}
                  alt="Maturity Spectrum 360 Image"
                  className="w-16 h-auto mr-1"
                />
                <p className="font-bold text-lg">AeroEdge IIoT</p>
              </div>
            </div>

            {/* hero_img */}
            <div className="block w-full">
              <img
                alt="hero"
                className="object-cover object-center  w-full mx-auto  rounded-2xl"
                src={hero_img}
              />
            </div>

            {/* Content for small screens */}
            <div className="block w-full lg:hidden">
              <div className="max-w-xl text-center">
                <p className="text-3xl md:pl-20 font-medium md:text-6xl text-slate-900">
                  <span className="text-[#252f80]">Transforming </span>
                    Industrial Operations
                </p>
                <p className="mt-4 text-lg tracking-tight text-gray-700 lg:text-xl md:pl-20">
                AeroEdge IIoT, the flagship product from AeroAegis Inc., represents a breakthrough in industrial automation and intelligence. Designed to seamlessly integrate with any Industrial IoT (IIoT) sensors and systems, AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration. This platform empowers industries to enhance operational efficiency, reduce downtime, and optimize maintenance strategies, all while ensuring robust data security and compliance.
                  </p>
              </div>
              <div className="flex flex-col items-center gap-3 mt-10 lg:flex-row">
                  <a className="inline-flex rounded-lg items-center justify-center px-6 py-4 text-center text-white duration-200 bg-[#252f80] hover:bg-blue-800" href="#">
                    <span className="font-semibold">Buy Now</span>
                  </a>
                </div>
            </div>

            {/* Content for larger screens */}
            <div className="relative items-center gap-12 m-auto lg:inline-flex hidden lg:flex">
              <div className="max-w-xl text-center lg:text-left">
                <div className="flex items-center ">
                  <img
                    src={ms360_img}
                    alt="Maturity Spectrum 360 Image"
                    className="w-16 h-auto mr-1"
                  />
                  <p className="font-bold text-lg">AeroEdge IIoT</p>
                </div>
                <div>
                  <p className="text-3xl font-medium md:text-6xl text-slate-900">
                    <span className="text-[#252f80]">Transforming </span>
                    Industrial Operations
                  </p>
                  <p className="mt-4 text-lg tracking-tight text-gray-700 lg:text-xl">
                
Welcome to the AeroEdge IIoT Platform, the ultimate solution for modern industrial environments seeking to leverage the power of digital transformation and Industry 4.0. Our platform is designed to provide real-time analytics, predictive maintenance, and comprehensive data security, all while ensuring seamless integration with existing industrial systems. Explore how AeroEdge can revolutionize your operations with our state-of-the-art architecture, powered by edge and cloud computing technologies.
 
                    </p>
                </div>
                <div className="flex flex-col items-center gap-3 mt-5 lg:flex-row">
                  <a className="inline-flex rounded-lg items-center justify-center px-6 py-4 text-center text-white duration-200 bg-[#252f80] hover:bg-blue-800" 
                  href="#">
                    <span className="font-semibold">Buy Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}