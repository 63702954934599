import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import SectionOne from './SectionOne'
import HeadSection from './Headsection'
import MainSection from './MainSection'
import MainSection2 from './MainSection2'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'
import MainSection5 from './MainSection5'
import FinalSection from './FinalSection'
import Commitment from './Commitment';
import Content1 from './Content1';
import MainSection6 from './MainSection6';


export default function index() {
  return (
    <div>
        <HeadSection/>
        <Content1/>
        <SectionOne/>
        <AnimationRevealPage>
        <MainSection/>
        <MainSection2/>
        <MainSection3/>
        <MainSection4/>
        <MainSection5/>
        <MainSection6/>
      
        <Commitment/>
        <FinalSection/>
        </AnimationRevealPage>
    </div>
  )
}
