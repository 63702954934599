import React from 'react'
import commitment_img from "../../../assets/img/Services/AI&ML/742.jpg";
export default function Commitment() {
  return (
    <div>
        <div class="relative group mt-4">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={commitment_img}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full  text-center transform -translate-x-1/2 -translate-y-1/2 text-white text-6xl font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36'>Our Commitment</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Our AI & ML Solutions represent the pinnacle of AeroAegis commitment to leveraging the
latest in technology to provide our clients with the tools they need to succeed in an everevolving digital landscape. By harnessing the power of LLMs, NLP, and LVM, we ensure that
our products are not just technologically advanced but also deeply aligned with the realworld needs and challenges of businesses today. AeroAegis is dedicated to transforming
the future of business operations, one intelligent solution at a time.
    </p>
  </div>
</div>
    </div>
  )
}
