import React from 'react'
import { Accordion, AccordionContent, AccordionPanel, AccordionTitle } from 'flowbite-react';

import pdf1 from '../../assets/pdf/InvestorRelations/Investor Pitch Document for AeroAegis Pvt Ltd.pdf'
import pdf2 from '../../assets/pdf/InvestorRelations/Discover AeroAegis Pvt Ltd.pdf'
import pdf3 from '../../assets/pdf/InvestorRelations/Leadership Team.pdf'
import excel1 from '../../assets/excel/Aero Aegis_Financial Model_Final.xlsx'
export default function MainSection() {

  return (
    <div className=' p-10'>
   
    <Accordion >
      <AccordionPanel >
        <AccordionTitle className=' bg-[#23307f] text-base lg:text-lg text-white hover:text-black'>Financial Projections</AccordionTitle>
        <AccordionContent>
          <p className="mb-2 text-base lg:text-lg text-gray-700 dark:text-gray-400">
          At AeroAegis, we are committed to transparency and providing our potential investors with a clear understanding of our financial outlook. Our projections are based on thorough market research, historical performance, and the potential impact of strategic initiatives. While all projections are subject to market conditions and unforeseen factors, we believe our growth trajectory is well-positioned for success.
          </p>
          <p className='text-slate-800 font-semibold '>Key Financial Projections</p>
          <div className=' px-10 pt-3'>
          <ul className=' list-disc '>
                            <li class="text-base lg:text-lg text-gray-700" >
                            <span className='  text-slate-800 font-semibold'>Revenue Growth:</span> Our projected revenue growth is fueled by increasing market demand, expanded product offerings, and strategic partnerships.
                            </li>
                            <li class="text-base lg:text-lg pt-1 text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Profit Margins:</span> We anticipate healthy profit margins driven by efficient operations, optimized resource allocation, and continuous innovation.
                            </li>
                            <li class="text-base lg:text-lg pt-1 text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Return on Investment (ROI): </span> We are confident in our ability to deliver a strong ROI for our investors, leveraging our growth strategies and value proposition.
                            </li>
                        </ul>
                        </div>
        </AccordionContent>
      </AccordionPanel>
      <AccordionPanel>
        <AccordionTitle className=' bg-[#23307f] text-base lg:text-lg text-white hover:text-black'> Investment Details</AccordionTitle>

        <AccordionContent>
        <p class=" font-semibold text-base lg:text-lg text-slate-800">
                        Why Invest in AeroAegis
                        </p>
                        <div className=' px-10 pt-3'>
                        <ul className=' list-disc '>
                            <li class="text-base lg:text-lg text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Opportunity for High Returns:</span> Our innovative solutions and comprehensive approach to organizational maturity are poised for substantial growth, offering investors the potential for high returns.
                            </li>
                            <li class="text-base lg:text-lg pt-1 text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Market Potential:</span> The global digital transformation market is expanding rapidly, and AeroAegis is well-positioned to capture a significant share of this growing market.
                            </li>
                            <li class="text-base lg:text-lg pt-1 text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Experienced Leadership: </span>  Our leadership team brings decades of collective experience in the IT industry, ensuring strategic decision-making and successful execution of our vision.
                            </li>
                        </ul>
                        </div>
        </AccordionContent>
      </AccordionPanel>
      <AccordionPanel>
        <AccordionTitle className=' bg-[#23307f] text-base lg:text-lg text-white hover:text-black'> Investment Opportunity</AccordionTitle>
        <AccordionContent>
        <p class="mb-2 text-base lg:text-lg text-gray-700">
                        Investing in AeroAegis offers a range of compelling benefits that align with your financial and strategic goals.
                        </p>
                        <div className=' px-10 pt-3'>
                        <ul className=' list-disc '>
                            <li class="text-base lg:text-lg text-gray-700" >
                            <span className='  text-slate-800 font-semibold'>Equity Ownership:</span> As an investor, you'll become a valued stakeholder in AeroAegis, sharing in the potential success and growth of the company.
                            </li>
                            <li class="text-base lg:text-lg  pt-1 text-gray-700" >
                            <span className='  text-slate-800 font-semibold'>Influence and Collaboration: </span>  Participate in shaping the future of AeroAegis by providing strategic insights and advisory support to our leadership team.
                            </li>
                            <li class="text-base lg:text-lg pt-1 text-gray-700" >
                            <span className='  text-slate-800 font-semibold'>Potential Dividends: </span> As the company achieves profitability, investors may have the opportunity to receive dividends, providing a potential ongoing stream of returns.
                            </li>
                            <li class="text-base lg:text-lg  pt-1 text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Positioned for Success: </span> Join us on a journey of growth and innovation, and be part of a company that is set to reshape the IT landscape.
                            </li>
                        </ul>
           </div>
           <p class="mb-2 text-base lg:text-lg mt-2 text-gray-700">
                        By investing in AeroAegis, you become a vital partner in our mission to drive organizational maturity and innovation. Together, we can unlock new levels of success, and your investment can play a pivotal role in realizing our ambitious goals.
                        </p>
        </AccordionContent>
      </AccordionPanel>

      <AccordionPanel>
        <AccordionTitle className='text-base lg:text-lg bg-[#23307f] text-white hover:text-black'> Leadership and Expertise</AccordionTitle>
        <AccordionContent>
        <p class="mb-2 text-base lg:text-lg text-gray-700">
        At AeroAegis, our strength lies in the expertise and dedication of our leadership team. Led by President (CEO) Dr. Madhu Kumar Reddy, an industry veteran with 18 years of IT experience, including global tech giants like Intel and Samsung. His vast knowledge spans AI, ML, DevOps, Cloud technologies, RPA, Blockchain, and more. With a Bachelor's degree in Computer Science, a Master's in Cloud Computing, and ongoing doctoral research in Cloud Technology, Dr. Madhu Kumar Reddy brings a wealth of experience and innovation.
                        </p>
                        <p class="text-base lg:text-lg mt-2 text-gray-700">
                        Supporting our vision is Dr. Nithin Manmohan, CTO and Chief Architect, boasting 13 years of IT experience. Dr. Nithin is an advanced AI/ML/NLP expert, well-versed in Big Data analytics, cloud development, data pipelines, and more. Having worked with esteemed companies such as HP and Delta Airlines, his deep understanding contributes significantly to AeroAegis's growth.


                        </p>

                        <p class="text-base lg:text-lg mt-2 text-gray-700">
                        In the advisory capacity, Bhaskar Nagaraja, with 25 years of IT experience, currently serves as a director at Intel, leading product R&D. His expertise in AI/ML within the IOTG domain adds significant value to AeroAegis's strategic direction.


                        </p>
                        <p class="text-base lg:text-lg mt-2 text-gray-700">
                        Balasubramanian Palaniappan, with over 20 years in the field, serves as an advisory board member. He's currently an Engineering Leader at Delta Airlines, bringing his profound experience in cloud migration and development to AeroAegis's table.


                        </p>
          
        </AccordionContent>
      </AccordionPanel>

      <AccordionPanel>
        <AccordionTitle className='text-base lg:text-lg bg-[#23307f] text-white hover:text-black'>  How to Invest</AccordionTitle>
        <AccordionContent>
        <p class="mb-2 text-base lg:text-lg text-gray-700">
        Investing in AeroAegis is an opportunity to be part of a dynamic journey towards innovation and growth. To express your interest or learn more about investment options, please contact our Investor Relations team:
                        </p>
                        <div className=' px-10 pt-3'>
                        <ul className=' list-disc '>
                            <li class="text-base lg:text-lg  text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Email: </span> invest@aeroaegis.com
                            </li>
                            <li class="text-base lg:text-lg pt-1 text-gray-700" >
                            <span className=' text-slate-800 font-semibold'>Phone:  </span>+91-9513177888
                            </li>
                            
                        </ul>
           </div>
           <p class="mb-2 text-base lg:text-lg mt-2 text-gray-700">
           Our dedicated team is ready to provide detailed information, answer your queries, and guide you through the investment process.
                        </p>
        </AccordionContent>
      </AccordionPanel>

      <AccordionPanel>
        <AccordionTitle className='text-base lg:text-lg bg-[#23307f] text-white hover:text-black'> Downloadable Resources</AccordionTitle>
        <AccordionContent>
        <p class="mb-2 text-base lg:text-lg text-gray-700">
        We value transparency and understand the importance of having access to comprehensive information. To assist you in making informed decisions, we offer the following downloadable resources:
                        </p>
                        <div className=' px-10 pt-3'>
                        <ul className=' list-disc '>
                           
                           <li class="text-base lg:text-lg text-gray-700" >
                          <a  href={pdf1} download="Investor Pitch Document for AeroAegis Pvt Ltd" target="_blank" rel="noreferrer"> 
                          <span className=' text-blue-800 font-semibold'>Investor Pitch Deck</span></a>
                           </li>
                           <li class="text-base lg:text-lg pt-1 text-gray-700" >
                           <a  href={pdf2} download="Discover AeroAegis Pvt Ltd" target="_blank" rel="noreferrer"> 
                            <span className='  text-blue-800  font-semibold'>Company Overview </span></a>
                           </li>
                           <li class="text-base lg:text-lg pt-1 text-gray-700" >
                           <a  href={pdf3} download="Leadership Team" target="_blank" rel="noreferrer"> 
                             <span className=' text-blue-800  font-semibold'>Leadership Profiles </span> </a>
                           </li>
                           <li class="text-base lg:text-lg pt-1 text-gray-700" >
                           <a  href={excel1} download="Financial Projections" target="_blank" rel="noreferrer"> 
                             <span className=' text-blue-800  font-semibold'>Financial Projections </span> </a>
                           </li>
                       </ul>
           </div>
           <p class="text-base lg:text-lg mt-2 text-gray-700">These resources provide deeper insights into AeroAegis's vision, growth strategy, and the potential benefits of partnering with us as an investor.</p>
                        <p class="text-base lg:text-lg mt-2 text-gray-700">Invest in AeroAegis and become part of a transformative journey that leverages cutting-edge technology to drive growth, innovation, and excellence in the IT landscape. Your investment propels us forward, shaping the future of organizations worldwide.</p>
        </AccordionContent>
      </AccordionPanel>
    </Accordion>
     </div>
                         
  
  )
}
