import React from 'react'
import main_img from "../../../assets/img/Products/Sys-AR/pexels-tima-miroshnichenko-6498953.jpg"
export default function MainSection1() {
  return (
    <div>
         <section class=" mt-5 px-4   pt-20 pb-20 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat bg-fixed"
            style={{
              backgroundImage: `url(${main_img})`,
            }}
          ></div>
          <div className="max-w-2xl mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            AeroSys AR not only enhances the user's understanding and interaction with the system but also improves the tool's adaptability and efficiency in resolving a wide variety of defects. Furthermore, the dynamic, real-time feedback mechanism significantly reduces the need for manual intervention, thereby boosting the overall efficacy of the system management process.
            </p>
          </div>
        </section>
          
    </div>
  )
}
