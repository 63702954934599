import React from "react";
import { FaCalendarAlt, FaArrowRight } from "react-icons/fa";
import aeroaegis_logo from "../../assets/img/PartnershipsCollaboration/aeroaegis.png";
import alibabaCloudLogo from "../../assets/img/PartnershipsCollaboration/alibabaCloudLogo.svg";

import { FaLink } from "react-icons/fa";
import { NavLink } from "react-router-dom";
export default function AlibabaPartnership() {
  return (
    <>
      <div className="h-fit flex flex-col items-center justify-center bg-gray-100 p-4 pt-0 mt-0">
        <div className="max-w-4xl w-full flex items-center justify-center bg-white rounded-xl shadow-2xl overflow-hidden transition-all duration-300 hover:shadow-3xl">
          <div className="md:flex">
            <div className="md:w-1/3 relative flex flex-col items-center justify-center gap-10 ">
              <img
                className="w-[125px]"
                src={aeroaegis_logo}
                alt="Portrait of a person"
              />
              <FaLink className="hidden h-6 w-6 text-stone-500 sm:block " />
              <div className="w-[200px]">
                <img src={alibabaCloudLogo} alt="alibaba cloud logo" />
              </div>

              <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-red-500 opacity-10 transition-opacity duration-300 hover:opacity-30"></div>
            </div>
            <div className="md:w-2/3 p-8">
              <h2 className="text-3xl font-extrabold text-gray-900 mb-4 leading-tight">
                AeroAegis Partners with Alibaba Cloud to Enhance Digital
                Transformation Services
              </h2>
              <div className="flex items-center text-gray-600 mb-4">
                <FaCalendarAlt className="mr-2" />
                <span>9th Nov, 2024</span>
              </div>
              <p className="text-gray-600 mb-6 leading-relaxed">
                AeroAegis Inc., a leader in AI-driven digital transformation
                solutions, is pleased to announce its official partnership with
                Alibaba Cloud, the digital technology and intelligence backbone
                of Alibaba Group. This strategic collaboration aims to deliver
                advanced cloud computing services and innovative solutions to
                clients across Europe, the Middle East, North Africa (MENA), and
                the United Arab Emirates (UAE).
              </p>
              <div className="flex items-center justify-between">
                <NavLink
                  to="/partnerships/alibaba-cloud"
                  className="inline-flex items-center px-6 py-3 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-colors duration-300 transform hover:scale-105"
                >
                  Explore more
                  <FaArrowRight className="ml-2" />
                </NavLink>
                <div className="flex items-center text-gray-500">
                  <span className="text-sm">Cloud partnership</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
