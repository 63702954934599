import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import HeroSection from './HeroSection'
import Mainsection1 from './Mainsection1'
import MainSection2 from './MainSection2'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'
import MainSection5 from './MainSection5'
import FinalWords from './FinalWords'

import Content1 from './Content1';
import MainSection6 from './MainSection6';
import MainSection7 from './MainSection7';

export default function index() {
  return (
    <div>
        <HeroSection/>
        <Content1/>
        <Mainsection1/>
        <AnimationRevealPage>
        <MainSection2/>
        <MainSection3/>
        <MainSection4/>
        <MainSection5/>
      <MainSection6/>
      <MainSection7/>
        {/* <Commitment/> */}
        <FinalWords/>
        </AnimationRevealPage>
    </div>
  )
}
