import React from 'react'

export default function cta1() {
  return (
    <div className="bg-white dark:bg-gray-800 pb-10 pt-16">
    <div className="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-2 lg:px-8 z-20">
        <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span className="block text-[#232f7f]">
            <span className='text-black'>Our</span> Values.
            </span>
        </h2>
    </div>
</div>
  )
}
