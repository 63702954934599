import React from "react";
import Innovation_img from "../../../assets/img/Our-Values/bulb-light-svgrepo-com.svg"
import Integrity_img from "../../../assets/img/Our-Values/multiple-users-silhouette.png"
import Collaboration_img from "../../../assets/img/Our-Values/teamwork-svgrepo-com.svg"
import Excellence_img from "../../../assets/img/Our-Values/star-fall-svgrepo-com.svg"
import Client_img from "../../../assets/img/Our-Values/hug.png"
import Agility_img from "../../../assets/img/Our-Values/run-svgrepo-com.svg"
import Respect_img from "../../../assets/img/Our-Values/handshake-svgrepo-com.svg"

export default function section_three() {
  return (
    <div className="bg-white mb-10 py-6 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        <div className="flex justify-center flex-wrap gap-8  ">
          <div className="flex  gap-4 md:gap-6 w-96">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-indigo-600 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
              <img
                src={Innovation_img}
                alt="Your Alt Text"
                className="p-2 w-full  object-cover rounded-lg"
              />
            </div>
            <div>
              <h3 className="mb-2 text-lg font-semibold md:text-2xl">Innovation</h3>
              <p className="mb-2 text-gray-500">
              We believe in continuously pushing the boundaries of what is possible, pioneering new ideas, and embracing a future-oriented mindset. We strive to bring cutting edge solutions to our clients.
              </p>
            </div>
          </div>

          <div className="flex gap-4 md:gap-6 w-96">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-indigo-600 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
              <img
                src={Integrity_img}
                alt="Your Alt Text"
                className="p-2 w-full object-cover rounded-lg"
              />
            </div>
            <div>
              <h3 className="mb-2 text-lg font-semibold md:text-2xl">Integrity</h3>
              <p className="mb-2 text-gray-500">
              We are committed to upholding the highest standards of honesty and ethics in all our dealings. We believe in doing the right thing, even when no one is watching.
              </p>
            </div>
          </div>

          <div className="flex gap-4 md:gap-6 w-96">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-indigo-600 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
              <img
                src={Collaboration_img}
                alt="Your Alt Text"
                className="p-2 w-full object-cover rounded-lg"
              />
            </div>
            <div>
              <h3 className="mb-2 text-lg font-semibold md:text-2xl">Collaboration</h3>
              <p className="mb-2 text-gray-500">
              We believe that the best results come from working together. We foster a collaborative culture internally and strive to build strong, lasting partnerships with our clients.
              </p>
            </div>
          </div>

          <div className="flex gap-4 md:gap-6 w-96">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-indigo-600 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
              <img
                src={Excellence_img}
                alt="Your Alt Text"
                className="p-1 w-full object-cover rounded-lg"
              />
            </div>
            <div>
              <h3 className="mb-2 text-lg font-semibold md:text-2xl">Excellence</h3>
              <p className="mb-2 text-gray-500">
              We are passionate about what we do and are driven to deliver top-quality work in all our endeavors. We continually challenge ourselves to exceed expectations and set new benchmarks for excellence.
              </p>
            </div>
          </div>

          <div className="flex gap-4 md:gap-6 w-96">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-indigo-600 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
              <img
                src={Client_img}
                alt="Your Alt Text"
                className="p-2 w-full object-cover rounded-lg"
              />
            </div>
            <div>
              <h3 className="mb-2 text-lg font-semibold md:text-2xl">Client Centricity</h3>
              <p className="mb-2 text-gray-500">
              We place our clients at the heart of everything we do. We are committed to understanding our clients' needs and providing solutions that are tailored to help them succeed.
              </p>
            </div>
          </div>

          <div className="flex gap-4 md:gap-6 w-96">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-indigo-600 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
              <img
                src={Agility_img}
                alt="Your Alt Text"
                className="p-2 w-full object-cover rounded-lg"
              />
            </div>
            <div>
              <h3 className="mb-2 text-lg font-semibold md:text-2xl">Agility</h3>
              <p className="mb-2 text-gray-500">
              We understand that the business and technology landscapes are constantly changing. We remain flexible and adaptable, always ready to adjust our strategies to best meet our clients' evolving needs.
              </p>
            </div>
          </div>

          <div className="flex gap-4 md:gap-6 w-96">
          <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-indigo-600 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
              <img
                src={Respect_img}
                alt="Your Alt Text"
                className="p-2 w-full object-cover rounded-lg"
              />
            </div>
            <div>
              <h3 className="mb-2 text-lg font-semibold md:text-2xl">Respect</h3>
              <p className="mb-2 text-gray-500">
              We value and respect all individuals – our employees, our clients, and our partners. We promote a diverse and inclusive environment that encourages open communication and appreciates individual contributions.
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
