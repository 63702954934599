import React from "react";
import hero from "../../assets/img/disclaimer_hero.jpg";
export default function DisclaimerContent1() {
  return (
    <div>
      <div className="relative flex lg:mb-10 flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src={hero}
            alt="hero"
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 pt-2 lg:my-40 lg:max-w-lg lg:pr-5">
            <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
            <h2 className="mb-5 text-4xl lg:text-5xl md:text-3xl   font-bold tracking-tight text-gray-900  sm:leading-none">
              Disclaimer
            </h2>
          </div>
        </div>
      </div>

      <hr className="mb-5 border-gray-300" />
      <div className=" px-5 lg:px-20 py-5">
        <p class="mb-3 text-gray-700 text-lg dark:text-gray-400">
          The website may contain inaccuracies and typographical and clerical
          errors. AeroAegis expressly disclaims any obligation(s) to update this
          website or any of the materials on this website. AeroAegis does not
          warrant the accuracy , adequacy or completeness of the materials or
          the reliability of any advice, opinion, statement or other information
          displayed or distributed through the Website.{" "}
        </p>
        <p class="mb-3 text-gray-700 text-lg dark:text-gray-400">
          You acknowledge that any reliance on any such opinion, advice,
          statement, memorandum, or information shall be at your sole risk.
          AeroAegis reserves the right, in its sole discretion, to correct any
          errors or omissions in any portion of the Website. AeroAegis may make
          any other changes to the Website, the materials and the products,
          programs, services or prices (if any) described in the Website at any
          time without notice.{" "}
        </p>
        <p class="mb-3 text-gray-700 text-lg dark:text-gray-400">
          This Website is for informational purposes only and should not be
          construed as technical advice of any manner. If any term in this Terms
          of Use is found by competent judicial authority to be unenforceable in
          any respect, the validity of the remainder of this Terms of Use will
          be unaffected, provided that such unenforceability does not materially
          affect the parties' rights under this Terms of Use.
        </p>
      </div>
    </div>
  );
}
