import React from 'react'
import img from '../../../assets/img/CloudMigration/strategy.jpg'
export default function MainSection3() {
  return (
    <div>
	 <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
          <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Cloud Strategy and Advisory
</h3>
				<p className="mt-3 text-lg dark:text-gray-400">
				Our cloud strategy and advisory services are designed to guide organizations through the 
decision-making process of cloud adoption, helping them to identify opportunities for growth 
and innovation. This includes:
        </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Cloud Readiness Assessment</h4>
							<p className="mt-2 dark:text-gray-400">
							Determining your organization's readiness for cloud 
adoption and identifying any potential challenges or areas for improvement.


              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Cloud Architecture Design</h4>
							<p className="mt-2 dark:text-gray-400">
							Crafting a comprehensive cloud architecture that supports 
your business objectives, incorporating considerations for security, compliance, and 
governance.
              </p>
						</div>
						
					</div>

					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Cost Management and Optimization</h4>
							<p className="mt-2 dark:text-gray-400">
							Providing insights and strategies to manage and 
optimize cloud spending, ensuring that you achieve the maximum return on your cloud 
investment.

              </p>
						</div>
						
					</div>
					
				</div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
  </div>
  )
}
