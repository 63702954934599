import React from 'react'
import Herosection from './Herosection'
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'

export default function index() {
  return (
    <div>
        <Herosection/>
        <AnimationRevealPage>
        <Section1/>
        <Section2/>
        <Section3/>
        </AnimationRevealPage>
    </div>
  )
}
