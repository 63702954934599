import React from "react";
import { FaCalendarAlt, FaArrowRight } from "react-icons/fa";
import img1 from "../../assets/img/news/featured_1.png";
export default function OtherSection() {
  return (
    <>
      <div className="h-fit flex flex-col items-center justify-center bg-gray-100 p-4 pt-0">
        <div className="max-w-4xl w-full flex items-center justify-center bg-white rounded-xl shadow-2xl overflow-hidden transition-all duration-300 hover:shadow-3xl">
          <div className="md:flex">
            <div className="md:w-1/2 relative">
              <img
                src={img1}
                alt="Portrait of a person"
                className="w-full h-64 md:h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-red-500 opacity-10 transition-opacity duration-300 hover:opacity-30"></div>
              <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                <h3 className="text-2xl font-bold mb-2">
                  Dr. Madhu Kumar Reddy
                </h3>
                <p className="text-sm">AI Visionary & Innovator</p>
              </div>
            </div>
            <div className="md:w-1/2 p-8">
              <h2 className="text-3xl font-extrabold text-gray-900 mb-4 leading-tight">
                Pioneering AI-Driven Transformation: A Visionary Journey
              </h2>
              <div className="flex items-center text-gray-600 mb-4">
                <FaCalendarAlt className="mr-2" />
                <span>30th Aug, 2024</span>
              </div>
              <p className="text-gray-600 mb-6 leading-relaxed">
                Embark on an extraordinary exploration of Dr. Madhu Kumar
                Reddy's groundbreaking work in AI-driven transformation.
                Discover how his visionary approach is reshaping industries and
                paving the way for a more intelligent future.
              </p>
              <div className="flex items-center justify-between">
                <a
                  href="https://cxolanes.com/ceo/pioneering-ai-driven-transformation-the-visionary-journey-of-dr-madhu-kumar-reddy/"
                  target="_blank"
                  className="inline-flex items-center px-6 py-3 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-colors duration-300 transform hover:scale-105"
                >
                  Read Full Article
                  <FaArrowRight className="ml-2" />
                </a>
                <div className="flex items-center text-gray-500">
                  <span className="text-sm">AI Impact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
