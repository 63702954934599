import React from 'react'
import img1 from "../../../assets/img/SREDvops/cardano-blockchain-platform.jpg";
export default function Commitment() {
  return (
    <div>
        <div class="relative group mt-4 mb-5">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img1}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full  text-center transform -translate-x-1/2 -translate-y-1/2 text-white text-6xl font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36'>AeroAegis Approach to SRE & DevOps</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Our strategy is rooted in the principles of SRE & DevOps—automation, continuous delivery, and
a proactive stance towards problem-solving. By integrating these methodologies with our
product offerings, we ensure not only the seamless operation of digital platforms but also their
resilience and scalability.
    </p>
  </div>
</div>
    </div>
  )
}
