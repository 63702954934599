import React from "react";
import plan_vid from "../../../assets/videos/CloudMigration/plan.mp4";
import choose_vid from "../../../assets/videos/CloudMigration/choose.mp4";
import review_vid from "../../../assets/videos/CloudMigration/review.mp4";
export default function Mainsection1() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">What We Do</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-[#23307f] scale-x-30 group-hover:scale-x-100" />
        </h2>
        <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md">
          "Empowering Businesses and Organizations by Analyzing Data."
        </p>
      </div>
      <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
        <a aria-label="View Item">
          <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <video
              muted
              loop
              autoPlay
              className="object-cover w-full h-56 md:h-64 xl:h-80"
              src={plan_vid}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex text-center mt-10 lg:mt-28 justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Plan and Prepare for Migration</h1>
        </div>
            <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
              <p className="mb-4 text-lg font-bold text-gray-100">
                Plan and Prepare for Migration
              </p>
              <p className="text-base tracking-wide text-gray-300">
                Freeze the business objective, understand the benefits,
                complexity and man power required, and plan the migration
                process beforehand.
              </p>
            </div>
          </div>
        </a>
        <a  aria-label="View Item">
          <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <video
              muted
              loop
              autoPlay
              className="object-cover w-full h-56 md:h-64 xl:h-80"
              src={choose_vid}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-10 text-center lg:mt-28 justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Choose Cloud Model</h1>
        </div>
            <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
              <p className="mb-4 text-lg font-bold text-gray-100">
                Choose Cloud Model
              </p>
              <p className="text-base tracking-wide text-gray-300">
                Freeze the cloud model and the type of cloud service to go for
                depending on the business requirement and the complexity of the
                application at hand.
              </p>
            </div>
          </div>
        </a>
        <a  aria-label="View Item">
          <div className="relative group  overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <video
              muted
              loop
              autoPlay
              className="object-cover w-full h-56 md:h-64 xl:h-80"
              src={review_vid}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-10 text-center lg:mt-28 justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Migrate Applications and Data & Review</h1>
        </div>
            <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
              <p className="mb-4 text-lg font-bold text-gray-100">
                Migrate Applications and Data & Review
              </p>
              <p className="text-base tracking-wide text-gray-300">
                Ensuring smooth transition of the cloud migration by taking into
                consideration important factors such as resource optimization
                and utilizing the full capabitilies of resources including man
                power.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
