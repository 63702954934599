import React from 'react'
import FinalQuote_img from "../../../assets/img/iiot/pexels-pixabay-416324.jpg"
import bg from "../../../assets/img/iiot/bg-img1.jpg"
export default function FinalQuote() {
  return (
    <div>
          <section class=" mt-5 px-4  pt-20 pb-20 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat bg-fixed"
            style={{
              backgroundImage: `url(${FinalQuote_img})`,
            }}
          ></div>
          <div className="max-w-2xl mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-2xl   text-white md:text-lg">
            AeroEdge IIoT stands out in the market by offering unparalleled edge computing capabilities that are both flexible and scalable. Our platform’s ability to operate in low-bandwidth environments, coupled with its advanced analytics and integration features, provides a competitive edge for organizations looking to optimize their industrial operations. By choosing AeroEdge IIoT, businesses can expect a significant return on investment through reduced operational costs, improved efficiency, and enhanced decision-making capabilities. AeroAegis Inc. is committed to continuous innovation, ensuring that our solutions meet the evolving needs of the industrial sector.
            </p>
          </div>
        </section>
    </div>
  )
}
