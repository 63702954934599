import React from 'react'

export default function Content1() {
  return (
    <div className=' py-10 px-5'>
      <div class="max-w-4xl mx-auto bg-[#23307f] shadow-blue-800 shadow-2xl rounded-xl">
        <div className=' px-5 py-5'>
      <p className="text-base text-center text-white md:text-lg">
      In the pursuit of operational excellence, reliability, and efficiency are paramount. Our
proficiency in Site Reliability Engineering (SRE) and DevOps practices empowers businesses to
maintain high-performing and reliable operations. By integrating our products with SRE and
DevOps methodologies, we help organizations build a culture of continuous improvement,
where deployment cycles are accelerated, downtime is minimized, and operational challenges
are proactively addressed. Our expertise ensures that your systems are not just operational but
are also resilient, scalable, and perfectly aligned with your business goals.


       
        </p></div>
        </div>
    </div>
  )
}
