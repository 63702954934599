import React from 'react'

export default function MainSection2() {
  return (
    <div>
         <section class="py-16 bg-[#EFF0F4] lg:py-20 dark:bg-gray-800 font-poppins">
        <div class="max-w-xl mx-auto">
            <div class="text-center ">
                <div class="flex flex-col items-center ">
                    <h1 class="text-5xl font-bold leading-tight dark:text-white"> AeroAegis  <span class="text-blue-500">
                    Roadmap
                        </span> </h1>
                    <div class="flex w-24 mt-1 mb-6 overflow-hidden rounded">
                        <div class="flex-1 h-2 bg-blue-200">
                        </div>
                        <div class="flex-1 h-2 bg-blue-400">
                        </div>
                        <div class="flex-1 h-2 bg-blue-600">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="flex flex-col justify-center ">
            <div class="w-full px-4 mx-auto lg:max-w-5xl ">
                <div class="relative">
                    <div
                        class="absolute hidden w-1 h-full transform -translate-x-1/2 bg-[#c9e2f5] dark:bg-gray-700 lg:block left-1/2">
                    </div>
                    <div class="space-y-2 lg:space-y-4">
                    
                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-end w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pl-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-7 -left-4">
                                                <div
                                                    class="hidden h-10 origin-top-right transform -rotate-45 bg-white dark:bg-gray-900 lg:block drop-shadow-lg">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">October</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            18</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2026</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            AeroSys AR</p>
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[3px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-4 h-4 bi bi-code-slash" viewBox="0 0 16 16">
                                            <path
                                                d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-start w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pr-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-3 -right-4">
                                                <div
                                                    class="hidden h-10 origin-bottom-left transform -rotate-45 bg-white shadow lg:block dark:bg-gray-900">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">December</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            13</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2025</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            AeroPulse AI</p>
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[2px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-4 h-4 bi bi-code-slash" viewBox="0 0 16 16">
                                            <path
                                                d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-end w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pl-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-7 -left-4">
                                                <div
                                                    class="hidden h-10 origin-top-right transform -rotate-45 bg-white dark:bg-gray-900 lg:block drop-shadow-lg">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">August</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            21</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2025</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            AeroInfra Vue</p>
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[3px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-4 h-4 bi bi-code-slash" viewBox="0 0 16 16">
                                            <path
                                                d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-start w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pr-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-3 -right-4">
                                                <div
                                                    class="hidden h-10 origin-bottom-left transform -rotate-45 bg-white shadow lg:block dark:bg-gray-900">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">May</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            25</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2025</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            AeroEdge IIoT</p>
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[4px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-4 h-4 bi bi-code-slash" viewBox="0 0 16 16">
                                            <path
                                                d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-end w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pl-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-7 -left-4">
                                                <div
                                                    class="hidden h-10 origin-top-right transform -rotate-45 bg-white dark:bg-gray-900 lg:block drop-shadow-lg">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">January</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            01</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2025</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            SmartChaosLab</p>
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[3px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-4 h-4 bi bi-code-slash" viewBox="0 0 16 16">
                                            <path
                                                d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                      
                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-start w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pr-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-3 -right-4">
                                                <div
                                                    class="hidden h-10 origin-bottom-left transform -rotate-45 bg-white shadow lg:block dark:bg-gray-900">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">November</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            12</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2024</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            Maturity Spectrum 360</p>
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[2px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-4 h-4 bi bi-code-slash" viewBox="0 0 16 16">
                                            <path
                                                d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                  
                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-end w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pl-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-7 -left-4">
                                                <div
                                                    class="hidden h-10 origin-top-right transform -rotate-45 bg-white dark:bg-gray-900 lg:block drop-shadow-lg">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">October</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            15</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2023</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            AeroAegis Limited
                                                            Central London, UK</p>
                                                            
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[3px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-3 h-3 bi bi-building" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z">
                                            </path>
                                            <path
                                                d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-start w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pr-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-3 -right-4">
                                                <div
                                                    class="hidden h-10 origin-bottom-left transform -rotate-45 bg-white shadow lg:block dark:bg-gray-900">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">October</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            03</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2023</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                            AeroAegis Inc (HQ) Delaware, US</p>
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[2px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-3 h-3 bi bi-building" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z">
                                            </path>
                                            <path
                                                d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        
                        <div>
                            <div class="flex flex-col items-center">
                                <div class="flex items-center justify-end w-full mx-auto">
                                    <div class="w-full lg:w-1/2 lg:pl-8">
                                        <div
                                            class="relative flex-1 mb-10 bg-white rounded shadow lg:mb-8 dark:bg-gray-900">
                                            <div
                                                class="absolute inline-block w-4 overflow-hidden -translate-y-1/2 top-7 -left-4">
                                                <div
                                                    class="hidden h-10 origin-top-right transform -rotate-45 bg-white dark:bg-gray-900 lg:block drop-shadow-lg">
                                                </div>
                                            </div>
                                            <div class="relative z-20 ">
                                                <div class="flex flex-wrap items-center">
                                                    <div class="p-4 md:w-1/4 ">
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">August</span>
                                                        <p
                                                            class="text-2xl font-bold text-gray-700 dark:text-gray-400 text-bold">
                                                            08</p>
                                                        <span
                                                            class="text-lg text-gray-700 dark:text-gray-400">2023</span>
                                                    </div>
                                                    <div
                                                        class="flex-1 p-4 pr-4 border-l border-gray-300 dark:border-gray-700">
                                                        <p
                                                            class="mb-2 text-xl font-bold text-gray-600 dark:text-gray-400">
                                                             AeroAegis Private Limited Bangalore, India</p>
                                                            
                                                        {/* <p class="text-gray-700 dark:text-gray-500">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            veniam libero facilis minus reprehenderit.
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="absolute flex items-center justify-center w-8 h-8 transform -translate-x-1/2 -translate-y-4 bg-blue-500 rounded-full dark:bg-gray-700 left-1/2 lg:translate-y-[3px]">
                                    <span class="text-gray-100 dark:text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="w-3 h-3 bi bi-building" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z">
                                            </path>
                                            <path
                                                d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
