import React from 'react'
import video_bg from '../../assets/videos/pexels-luis.mp4'
export default function Section5() {
  return (
    <div className="relative mt-7 bg-gray-50">
       <video
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
      >
        <source src={video_bg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="px-4 py-1  mx-auto h-[460px] w-full md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
            <a  className="mb-6 sm:mx-auto">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-slate-100">
              </div>
            </a>
            <div className="max-w-xl mb-10 md:mx-auto text-center lg:max-w-2xl md:mb-12">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-purple-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                  >
                    <defs>
                      <pattern
                        id="700c93bf-0068-4e32-aafe-ef5b6a647708"
                        x="0"
                        y="0"
                        width=".135"
                        height=".30"
                      >
                        <circle cx="1" cy="1" r=".7" />
                      </pattern>
                    </defs>
                    <rect
                      fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)"
                      width="52"
                      height="24"
                    />
                  </svg>
                  <span className="relative text-5xl ">How We Help</span>
                </span>{" "}
              </h2>
              <p className="relative lg:text-2xl  text-indigo-100 md:text-lg">
                We understand that businesses today face unparalleled challenges
                and opportunities. Technology evolves at breakneck speed, and
                competition is fierce. That's where we come in. We're not just
                an IT company; we're architects of solutions, creators of
                possibilities, and champions of your success.
              </p>
            </div>
        
          </div>
        </div>
      </div>
    </div>
  );
}
