import React from 'react'
import DisclaimerContent1 from './DisclaimerContent1'

export default function index() {
  return (
    <div>
        <DisclaimerContent1/>
    </div>
  )
}
