import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import MainSection2 from './MainSection2'
import MainSection1 from './MainSection1'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'

import FinalSection from './FinalSection'
import HeroSection from './HeroSection'
import Content1 from './Content1';

export default function index() {
  return (
    <div>
      <HeroSection/>
      <Content1/>
        <MainSection1/>
        <AnimationRevealPage>
        <MainSection2/>
        <MainSection3/>
        <MainSection4/>
      
        <FinalSection/>
        </AnimationRevealPage>
    </div>
  )
}
