import React from "react";
import {
  FaHandshake,
  FaCloudUploadAlt,
  FaChartLine,
  FaGlobe,
} from "react-icons/fa";
import alibabaCloudLogo from "../../../assets/img/PartnershipsCollaboration/alibabaCloudLogo.svg";

import { NavLink } from "react-router-dom";

export default function PatnersList() {
  return (
    <section className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-6 lg:w-[100%] flex-shrink-0 md:w-[576px] sm:w-[544px] m-auto">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Our Strategic Partners:
            </h2>
            <h1 className="text-4xl sm:text-5xl font-bold text-[#23307F]">
              Alibaba Cloud Partnership
            </h1>
            <p className="text-lg text-gray-600">
              As an official Alibaba Cloud partner, AeroAegis leverages
              Alibaba's robust cloud infrastructure to enhance our AI-driven
              digital transformation solutions across Europe, MENA, and UAE.
              This collaboration enables us to offer advanced multi-cloud
              strategies, big data analytics, and IoT optimizations, ensuring
              our clients receive cutting-edge, scalable, and secure cloud
              services.
            </p>
            <div className="flex space-x-4">
              <NavLink
                to="/partnerships/alibaba-cloud "
                className="bg-[#23307F] text-white px-6 py-3 rounded-md font-semibold"
              >
                Explore more
              </NavLink>
            </div>
          </div>
          <div className="relative">
            <div className="absolute inset-0  rounded-xl"></div>
            <div className="relative z-10 p-8 flex flex-col items-center gap-10">
              <img src={alibabaCloudLogo} alt="alibaba cloud logo" />
              <div className="grid md:grid-cols-2 gap-6 grid-cols-1">
                <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                  <FaHandshake className="text-4xl text-[#23307F] mr-4" />
                  <div>
                    <h3 className="text-sm font-semibold">
                      Strategic Alliance
                    </h3>
                    <p className="text-sm text-gray-600">
                      Powerful partnership for innovation
                    </p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                  <FaCloudUploadAlt className="text-4xl text-[#23307F] mr-4" />
                  <div>
                    <h3 className="text-sm font-semibold">Cloud Solutions</h3>
                    <p className="text-sm text-gray-600">
                      Scalable and secure infrastructure
                    </p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                  <FaChartLine className="text-4xl text-[#23307F] mr-4" />
                  <div>
                    <h3 className="text-sm font-semibold">Data Analytics</h3>
                    <p className="text-sm text-gray-600">
                      Advanced insights for growth
                    </p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                  <FaGlobe className="text-4xl text-[#23307F] mr-4" />
                  <div>
                    <h3 className="text-sm font-semibold">Global Reach</h3>
                    <p className="text-sm text-gray-600">
                      Expansive network coverage
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
