import React from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <footer class="bg-gray-900  pt-4 pb-8 xl:pt-8">
    <div class="max-w-screen-lg px-4 mx-auto text-gray-400 xl:max-w-screen-xl sm:px-6 md:px-8 ">
        <ul class="flex flex-wrap justify-center pb-8 text-lg font-light">
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
                <div class="">
                    <h2 class="text-gray-200 font-medium text-md uppercase mb-4">
                        Quick links
                    </h2>
                    <ul>
                    <Link to="/locations">
                         <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a href="#">
                                Locations
                            </a>
                        </li></Link>
                        <Link to="/news-room">
                         <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a href="#">
                                News Room
                            </a>
                        </li></Link>
                        <Link to="/investor-relations">    <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a href="#">
                                Investor Relations
                            </a>
                        </li></Link>                        
                        <Link to="/careers">  <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a >
                                Careers
                            </a>
                        </li></Link>
                        <Link to="/disclaimer">   <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a >
                               Disclaimer
                            </a>
                        </li></Link>
                        <Link to="/terms-conditions">    <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a >
                                Terms & Conditions
                            </a>
                        </li></Link>
                    {/* <Link to="/contact-us">   <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a>
                               Contact Us
                            </a>
                        </li></Link> */}
                       
                       
                        <Link to="/csr">  <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <a href="#">
                                CSR
                            </a>
                        </li></Link>
                    
                      
                      
                    </ul>
                </div>
            </li>
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
                <div class="">
                    <h2 class="text-gray-200  text-md font-medium uppercase mb-4">
                        Business Hours
                    </h2>
                    <ul>
                        <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <p >
                            Monday - Friday
                            </p>
                            <p className=' font-normal text-base'>09:00 am - 07:00 pm</p>
                        </li>
                        <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <p >
                            Saturday
                            </p>
                            <p className=' font-normal text-base'>09:00 am - 12:00 pm</p>
                        </li>
                        <li class="mb-4 transition-colors text-base duration-200 hover:text-gray-600 ">
                            <p >
                            Sunday
                            </p>
                            <p className=' font-normal text-base'>Closed</p>
                        </li>
                       
                    </ul>
                </div>
            </li>
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
            <div className="">
            <h2 class="text-gray-200 text-md font-medium uppercase mb-4">Reach Us</h2>
          <div className="flex">
            <p className="mr-1 text-base">Phone :</p>
            <a
              href="tel:+1 (302) 497-4435"
              aria-label="Our phone"
              title="Our phone"
              className="transition-colors md:text-base lg:text-base text-[12px] duration-300 text-[#6c32ff] hover:text-white"
            >
              +1 (302) 497-4435
            </a>
          </div>
          <div className="flex">
            <p className="mr-1 text-base">Email :</p>
            <a
              href="mailto:info@aeroaegis.com"
              aria-label="Our email"
              title="Our email"
              className="transition-colors md:text-base lg:text-base text-[12px] duration-300 text-[#6c32ff] hover:text-white"
            >
              info@aeroaegis.com
            </a>
          </div>
         
        </div>
            </li>
        </ul>
        <div class="pt-8 flex border-t border-gray-200 max-w-xs mx-auto items-center justify-center gap-10">
            <a href="#">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                    </path>
                </svg>
            </a>
            <a href="#">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                    </path>
                </svg>
            </a>
          
            <a target='_blank' href="https://www.linkedin.com/company/96893605">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                    </path>
                </svg>
            </a>

            <a target='_blank' href="https://www.youtube.com/channel/UCZDzOYE4W-stKNR9AY2dUmg">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-youtube text-xl transition-colors duration-200 hover:text-gray-800" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
</svg>
            </a>
          
        </div>
        <div class="text-center pt-10 sm:pt-12 font-light flex items-center justify-center">
            <form class="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <div class=" relative ">
                    <input type="text" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#252f80] focus:border-transparent" placeholder="Email"/>
                    </div>
                    <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-[#252f80] rounded-lg shadow-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                        Subscribe
                    </button>   
                </form>
            </div>
            <div class="text-center pt-10 sm:pt-12 font-light flex items-center justify-center">
            <p className="text-sm text-gray-500">
            © Copyright <span>{(new Date().getFullYear())}</span> AeroAegis. All rights reserved.
          </p>
            </div>
        </div>
    </footer>
  )
}
