import React from 'react'
import visionary_img from '../../../assets/img/discover_aeroaegis/visionary.jpg'
export default function MainSection2() {
  return (
    <section className="">
          {/* <div class="text-left lg:text-center mt-10 mb-10">
                    <h2 class="text-4xl font-semibold tracking-tighter text-zinc-900">
                    Here's why AeroAegis is the right choice 
                      <span class="block">for your transformation journey.</span>
                    </h2>
          </div> */}
            <span class="mb-10 mt-10 block text-center text-4xl font-bold text-[#23307F]">
            Here's why AeroAegis is the right choice <br/>for your transformation journey.
            <div>
              <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black"></p>
            </div>
          </span>
          <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
            <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] ">
              <h3 className="text-2xl font-bold tracki sm:text-3xl ">
              Visionary Leadership: Pioneering Today for Tomorrow's Success
              </h3>
              <div className="flex space-x-2 sm:space-x-4 pt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                <div className="space-y-2">
                  <p className="text-lg font-medium leading">
                  Guided by the foresight of our founders, Dr. Madhu Kumar Reddy and Dr. Nithin
Manmohan, AeroAegis benefits from leadership that not only understands the current
landscape but anticipates future technological shifts. This visionary leadership is instrumental
in steering AeroAegis toward groundbreaking achievements, ensuring we remain at the
forefront of digital innovation.

                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 xl:w-3/5 ">
              <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                <img
                  src={visionary_img}
                  alt=""
                  className="rounded-lg shadow-lg object-cover  h-80 sm:min-h-96"
                />
              </div>
            </div>
          </div>
        </section>
  )
}
