import React from 'react'
import main_img from "../../../assets/img/Products/InfraVue/pexels-nothing-ahead-7457656.jpg"
export default function MainSection1() {
  return (
    <div>
         <section class=" mt-5  pt-20 pb-20 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
            style={{
              backgroundImage: `url(${main_img})`,
            }}
          ></div>
          <div className="max-w-2xl px-4 mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            Infrastructure design, particularly in the cloud age, is an intricate ballet of interconnected systems, platforms, and services. While its complexity offers robustness and scalability, it often becomes a barrier for those who aren't seasoned infrastructure architects. AeroInfra Vue's central mission is to break down these complexities, making infrastructure design an intuitive process, irrespective of one's technical expertise.
            </p>
          </div>
        </section>
          
    </div>
  )
}
