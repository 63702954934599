import React from "react";
import main_section_img from "../../../assets/img/Products/AeroPerformai/smooth-elegant-transparent-blue-cloth-separated-blue-background.jpg";
export default function Section1() {
  return (
    <div>
      <section class=" mt-5 px-4  pt-20 pb-20 relative">
        <div
          class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
          style={{
            backgroundImage: `url(${main_section_img})`,
          }}
        ></div>
        <div className="max-w-2xl mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
          <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
          AeroPulse AI is your next-generation partner in cloud performance management. Designed to seamlessly integrate with any cloud environment, AeroPulse AI offers a comprehensive solution for monitoring, predicting, and optimizing the performance of your cloud-hosted applications and systems. Through cutting-edge artificial intelligence and machine learning technologies, AeroPulse AI ensures your infrastructure operates at peak efficiency, enabling your business to thrive in today's digital landscape.
          </p>
        </div>
      </section>
    </div>
  );
}
