import React from 'react'
import Herosection from './Herosection'
import OtherSection from './OtherSection'
import FinalSection from './FinalSection'
import MainSection from './MainSection'
import MainSection2 from './MainSection2'

export default function index() {
  return (
    <div>
        <Herosection/>
        <OtherSection/>
        <MainSection/>
        <MainSection2/>
        <FinalSection/>
    </div>
  )
}
