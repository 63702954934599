import React from "react";
import Herosection from "./Herosection";
import FinalSection from "./FinalSection";
import OtherSection from "./OtherSection";
import Commitment from "./Commitment";
import AlibabaPartnership from "./AlibabaPatnership";

export default function index() {
  return (
    <div>
      <Herosection />
      <Commitment />
      <div className=" text-center ">
        <h2 className="mb-10 font-sans text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
          <span className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></span>
          <br />
          Latest
        </h2>
      </div>
      <div className="flex flex-col py-10 gap-5 bg-slate-100">
        <AlibabaPartnership />
        <OtherSection />
      </div>
      {/* <Alibaba /> */}
      <FinalSection />
    </div>
  );
}
