import React from "react";
import vid1 from "../../../assets/videos/Blockchain/video1.mp4";
import vid2 from "../../../assets/videos/Blockchain/video2.mp4";
import vid3 from "../../../assets/videos/Blockchain/video3.mp4";
import vid4 from "../../../assets/videos/Blockchain/ledger.mp4";
export default function Mainsection1() {
  return (
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">What We Do</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-[#23307f] scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md">
            "Engage Audiences by Creating and Developing Visual Effects and
            Animation for Businesses and Organizations."
          </p>
        </div>

        <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-4 sm:row-gap-6 sm:grid-cols-2">
    <a  aria-label="View Item">
      <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
      <video  muted
                  loop autoPlay
              className="object-cover w-full h-72 md:h-80 xl:h-96"
              src={vid1}
              alt=""
            />
            <div className="absolute inset-0 px-2 py-4  transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex text-center mt-20  md:mt-16  xl:mt-36 justify-center font-semibold align-content-center text-2xl group-hover:hidden'>
          Record Transaction</h1>
        </div>  
        <div className="absolute inset-0 px-6 py-5 lg:py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100">
          <p className="mb-2 md:text-base text-base lg:text-[12px] xl:text-base font-bold text-gray-100">Record the transaction</p>
          <p className="text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          Record details pertaining to movement of physical or digital assets between parties in the blockchain network which includes details like the parties involved in the transaction, when and where the transaction occured, how much of the asset was exchanged and list of pre-conditions that were met during the transaction.
          </p>
         
        </div>
      </div>
    </a>

    <a  aria-label="View Item">
      <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
      <video  muted
                  loop autoPlay
              className="object-cover w-full h-72 md:h-80 xl:h-96"
              src={vid2}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white mt-20 text-center flex md:text-center md:mt-16 lg:text-center xl:text-center xl:mt-36 justify-center font-semibold align-content-center text-2xl group-hover:hidden'>Gain Consensus</h1>
        </div> 
        <div className="absolute inset-0 px-6 py-5 lg:py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          <p className="mb-2 text-base md:text-base lg:text-[12px] xl:text-base font-bold text-gray-100">
          Gain Consensus
          </p>
          <p className="text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          Most participants on the distributed blockchain network must agree that the recorded transaction is valid.
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          Depending on the type of network, rules of agreement can vary but are typically established at the start of the network.
          </p>
        </div>
      </div>
    </a>

    <a  aria-label="View Item">
      <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
      <video  muted
                  loop autoPlay
              className="object-cover w-full h-72 md:h-80 xl:h-96"
              src={vid3}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex md:text-center mt-20 text-center md:mt-16 lg:text-center xl:text-center xl:mt-36 justify-center font-semibold align-content-center text-2xl group-hover:hidden'>
          Link Blocks</h1>
        </div> 
        <div className="absolute inset-0 px-6 py-5 lg:py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          <p className="mb-2 md:text-base text-base lg:text-[12px] xl:text-base font-bold text-gray-100">Link the Blocks</p>
          <p className="text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          Along with the transactions, a cryptographic hash is also appended to the new block. The hash acts as a chain that links the blocks together. If the contents of the block are intentionally or unintentionally modified, the hash value changes, providing a way to detect data tampering.
          </p>
          <p className="pt-2 text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          Thus, the blocks and chains link securely, and you cannot edit them.
          </p>
        </div>
      </div>
    </a>

    <a  aria-label="View Item">
      <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
      <video  muted
                  loop autoPlay
              className="object-cover w-full h-72 md:h-80 xl:h-96"
              src={vid4}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-20 text-center md:text-center md:mt-16 lg:text-center xl:text-center xl:mt-36 justify-center font-semibold align-content-center text-2xl group-hover:hidden'>
          Share Ledger</h1>
        </div> 
        <div className="absolute inset-0 px-6 py-5 lg:py-2 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          <p className="mb-2 md:text-base text-base lg:text-[12px] xl:text-base font-bold text-gray-100">Share the ledger</p>
          <p className="text-[13px] lg:text-[12px] xl:text-base tracking-wide text-gray-300">
          The system distributes the latest copy of the central ledger to all participants.
          </p>
        
        </div>
      </div>
    </a>
  </div>
      </div>
    </div>
  );
}
