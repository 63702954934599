import React from 'react'
import hero from '../../assets/img/Investor_relations/hero.jpg'
export default function Herosection() {
  return (
    <div className="relative mb-10 flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
    <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
      <svg
        className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
        viewBox="0 0 100 100"
        fill="currentColor"
        preserveAspectRatio="none slice"
      >
        <path d="M50 0H100L50 100H0L50 0Z" />
      </svg>
      <img
        className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
        src={hero}
        alt=""
      />
    </div>
    <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
      <div className="lg:mb-16 pt-2 lg:my-40 lg:max-w-lg lg:pr-5">
        <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
        <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Investor Relations
        </h2>
        <p className="pr-5 mb-5 text-base text-gray-700 lg:text-lg">
        Helping People, Businesses and Organizations in reaching their full potential.
        </p>
      </div>
    </div>
  </div>
    // <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    //   <div className="flex flex-col max-w-screen-lg overflow-hidden bg-white border rounded shadow-sm lg:flex-row sm:mx-auto">
    //     <div className="relative lg:w-1/2">
    //       <img
    //         src="https://images.pexels.com/photos/7413994/pexels-photo-7413994.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    //         alt=""
    //         className="object-cover w-full lg:absolute h-80 lg:h-full"
    //       />
    //       <svg
    //         className="absolute top-0 right-0 hidden h-full text-white lg:inline-block"
    //         viewBox="0 0 20 104"
    //         fill="currentColor"
    //       >
          
    //       </svg>
    //     </div>
    //     <div className="flex flex-col justify-center p-8 bg-white lg:p-16 lg:pl-10 lg:w-1/2">
    //       <div>
    //         <p className="inline-block lg:px-5 py-1  mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#23307f]">
            
    //         </p>
    //       </div>
    //       <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
    //       Investor Relations
    //       </h5>
    //       <span className=' font-bold'>Our Purpose</span>
    //       <p className="mb-5 text-gray-800">
    //       Helping People, Businesses and Organizations in reaching their full potential.
    //       </p>
       
    //     </div>
    //   </div>
      
    // </div>
  )
}
