import React from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../assets/img/HomePage/2583.jpg'
export default function PoweredbyAero() {
  return (
    <div>
     <section class="bg-white ">
    <div class="container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row">
        <div class="flex justify-center xl:w-1/2">
            <img class="h-80 w-80 sm:w-[28rem] sm:h-[28rem] flex-shrink-0 object-cover rounded-full" 
            src={img1} alt=""/>
        </div>

        <div class="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
            <h2 class="text-2xl font-semibold tracking-tight text-gray-800 xl:text-4xl ">
            Your Success, Powered by <span className=' text-[#252f80]'>AeroAegis</span>

            </h2>

            <p class="block max-w-2xl mt-4  text-base lg:text-lg  text-gray-700 ">
            Embark on a journey with AeroAegis where your business is not just transformed but
transcendent. Our products lay the foundation for unparalleled success, bolstered by our
commitment to your growth. In partnership, we venture beyond the conventional, turning
challenges into triumphs in the digital epoch.
             </p>

            <div class="mt-6 sm:-mx-2">

              <Link to="/aeroaegis-capabilities">  <a 
                    class="inline-flex items-center justify-center w-full px-4 text-sm py-2.5 mt-4 overflow-hidden text-white transition-colors duration-300 bg-blue-600 rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                   

                    <span class="mx-2">
                        Learn More
                    </span>
                </a></Link>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}
