import React from 'react'
import benchmarking_img from "../../../assets/img/iiot/detection.jpg"

export default function Whytochoose() {

//   
return (
    <div>
        <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-5 mx-auto">
        <h1 class="text-2xl text-center font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">Why Choose <br/> <span class="underline decoration-blue-500">AeroEdge?</span></h1>



        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-2">
            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Real Time Analytics</h1>

                <p class="text-gray-500 dark:text-gray-300 font-semibold">
				Make data-driven decisions faster with our edge-computing capabilities.

                </p>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Predictive Maintenance</h1>

                <p class="text-gray-500 dark:text-gray-300 font-semibold">
				Reduce downtime and extend equipment life with advanced AI algorithms.

                </p>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Scalability</h1>

                <p class="text-gray-500 dark:text-gray-300 font-semibold">
				Whether you're a small enterprise or a large industrial complex, our platform scales effortlessly to meet your needs.

                </p>
            </div>

            <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Comprehensive Security</h1>

                <p class="text-gray-500 dark:text-gray-300 font-semibold">
				Rest assured that your data is safe and compliant with industry standards.

                </p>
            </div>
 
        </div>
    </div>
</section>
    </div>
  )
}
