import React from 'react'
import data_img from "../../../assets/img/Consulting/csi.jpg";
export default function MainSection4() {
  return (
    <div>
	  <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
          <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Custom System Integration</h3>
				<p className="mt-3 text-lg dark:text-gray-400">
				At AeroAegis, we understand that every business is unique, with its own set of challenges and
requirements. Our custom system integration services are designed to bring together your
disparate systems, applications, and software into a unified, coherent platform that supports
your business objectives. We focus on:
        </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">API Development and Management</h4>
							<p className="mt-2 dark:text-gray-400">
							Creating and managing APIs that enable different
software components and third-party applications to communicate effectively,
enhancing functionality and user experience.

              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Legacy System Modernization</h4>
							<p className="mt-2 dark:text-gray-400">
							Updating and integrating legacy systems with modern
applications and platforms, ensuring they contribute effectively to your digital strategy
without the need for complete system overhauls.
              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Cloud Integration</h4>
							<p className="mt-2 dark:text-gray-400">
							Seamlessly integrating cloud services with on-premises systems,
providing flexibility, scalability, and the foundation for a hybrid digital infrastructure that
supports future growth.
              </p>
						</div>
					</div>
					
				</div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={data_img}
                alt="marketing"
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
  </div>
  )
}
