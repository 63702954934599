import React from 'react'

export default function Content1() {
  return (
    <div className=' py-10 px-5'>
      <div class="max-w-4xl mx-auto bg-[#23307f] shadow-blue-800 shadow-2xl rounded-xl">
        <div className=' px-5 py-5'>
      <p className="text-base text-center text-white md:text-lg">
      The cloud is a fundamental element of digital transformation, offering flexibility, scalability, and
efficiency. Our expertise in cloud solutions and migration is designed to support businesses in
their journey to the cloud, ensuring a smooth transition that minimizes disruption and
maximizes the benefits of cloud computing. Utilizing our product suite, we develop bespoke
cloud strategies that align with business objectives, ensuring that cloud architectures are
resilient, secure, and optimized for performance. Whether it's migrating legacy systems or
deploying new cloud-native applications, AeroAegis stands as a guiding light, ensuring that your
cloud journey is successful and transformative.


       
        </p></div>
        </div>
    </div>
  )
}
