import React from 'react'

export default function cta() {
  return (
<div className="bg-white dark:bg-gray-800 pb-5 mt-10">
    <div className="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-2 lg:px-8 z-20">
        <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span className="block">
            We continuously strive to
            </span>
            <span className="block text-[#232f7f]">
            Adapt <span className='text-black'>and</span> Improve.
            </span>
        </h2>
    </div>
</div>
  )
}
