import React from 'react'

export default function Content1() {
  return (
    <div className=' py-10 px-5'>
      <div class="max-w-4xl mx-auto bg-[#23307f] shadow-blue-800 shadow-2xl rounded-xl">
        <div className=' px-5 py-5'>
      <p className="text-base text-center text-white md:text-lg">
      At AeroAegis, we recognize the critical role that automation and seamless system integration
play in the modern digital landscape. Our approach is centered around leveraging our advanced
product suite to automate business processes, reduce manual intervention, and ensure that
different systems work together in harmony. This not only streamlines operations but also
unlocks new levels of efficiency and productivity. By integrating our products with existing
systems, we enable businesses to create a cohesive and automated operational framework that
supports scalable growth and adaptability.

       
        </p></div>
        </div>
    </div>
  )
}
