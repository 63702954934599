import React, { useState } from 'react';
import { FaCloudUploadAlt, FaBolt, FaShieldAlt, FaChartBar, FaChevronRight, FaLightbulb, FaUsers, FaRocket } from 'react-icons/fa';

const CaseStudyPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('client-background');

  const tabs = [
    { id: 'client-background', label: 'Client Background' },
    { id: 'challenges', label: 'Challenges' },
    { id: 'approach', label: 'Approach' },
    { id: 'implementation', label: 'Implementation' },
  ];

  return (
    <div className=" mx-auto px-24 py-8 w-screen">
      <h1 className="text-3xl font-bold mb-8">The Case Study Approach</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Abstract</h2>
        <p className="text-gray-700">
          The case study approach allows in-depth, multi-faceted explorations of complex issues in
          their real-life settings. The value of the case study approach is well recognised in the fields of
          business, law and policy, but somewhat less so in health services research. Based on our
          experiences of conducting several health-related case studies, we reflect on the different
          types of case study design, the specific research questions this approach can help answer, the
          data sources that tend to be used, and the particular advantages and disadvantages of
          employing this methodological approach.
        </p>
      </div>

      <div className="mb-8">
        <div className="flex border-b">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`py-2 px-4 ${
                activeTab === tab.id
                  ? 'border-b-2 border-blue-500 text-blue-500'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="mt-4">
          {activeTab === 'client-background' && (
            <div>
              <h3 className="text-xl font-semibold mb-2">Client Background</h3>
              <p className="text-gray-700">
                Our client, a prominent player in the healthcare industry, operates a network of hospitals 
                and clinics, catering to millions of patients annually. Their legacy infrastructure, comprising 
                on-premises data centers and monolithic applications, had reached its limits. As patient numbers 
                grew and healthcare data increased exponentially, the organization faced significant challenges 
                in data management, response times, compliance, and scalability.
              </p>
            </div>
          )}
          {activeTab === 'challenges' && (
            <div>
              <h3 className="text-xl font-semibold mb-2">Challenges</h3>
              <ul className="list-disc list-inside text-gray-700">
                <li>Legacy Infrastructure Limitations</li>
                <li>Inefficient Data Management and Processing</li>
                <li>Compliance and Security Challenges</li>
                <li>Scalability and Performance Bottlenecks</li>
                <li>Limited Analytics and Decision-Making Capabilities</li>
              </ul>
            </div>
          )}
          {activeTab === 'approach' && (
            <div>
              <h3 className="text-xl font-semibold mb-2">Our Approach</h3>
              <p className="text-gray-700">
                AeroAegis Inc. adopted a holistic, phased approach combining deep industry expertise 
                with cutting-edge technology. Our strategy centered on delivering a robust, cloud-native, 
                event-driven solution powered by big data analytics, all while ensuring regulatory 
                compliance and system resilience.
              </p>
            </div>
          )}
          {activeTab === 'implementation' && (
            <div>
              <h3 className="text-xl font-semibold mb-2">Implementation</h3>
              <p className="text-gray-700">
                The solution implementation phase was critical to the client's digital transformation journey. 
                AeroAegis Inc. employed a systematic approach to design and deploy a cloud-native, event-driven, 
                and big data-enabled architecture tailored to meet the unique needs of the healthcare industry.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-8 mb-8">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <FaCloudUploadAlt className="mr-2 text-blue-500" />
            Cloud-Native Architecture
          </h3>
          <p className="text-gray-700">
            Implemented a microservices-based design with containerization using Docker and Kubernetes, 
            enabling scalable and flexible healthcare applications.
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <FaBolt className="mr-2 text-yellow-500" />
            Event-Driven Architecture
          </h3>
          <p className="text-gray-700">
            Utilized Apache Kafka for event streaming, enabling real-time data processing and 
            improving responsiveness in critical healthcare scenarios.
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <FaShieldAlt className="mr-2 text-green-500" />
            Security and Compliance
          </h3>
          <p className="text-gray-700">
            Implemented end-to-end encryption, role-based access control, and automated compliance 
            reporting to ensure HIPAA compliance and data protection.
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <FaChartBar className="mr-2 text-purple-500" />
            Big Data Analytics
          </h3>
          <p className="text-gray-700">
            Developed a centralized data lake and implemented real-time analytics dashboards, 
            enabling data-driven decision making and predictive healthcare interventions.
          </p>
        </div>
      </div>

      <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-8">
        <p className="text-blue-700">
          This case study demonstrates the effectiveness of cloud-native solutions and big data 
          analytics in transforming healthcare operations, improving patient outcomes, and ensuring 
          regulatory compliance.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Why Choose AeroAegis Inc.?</h2>
        <p className="text-gray-700 mb-4">
          AeroAegis Inc. combines deep industry expertise, innovative proprietary frameworks, and strategic 
          technology partnerships to deliver end-to-end digital transformation solutions. Our team of experts 
          from various Centers of Excellence (COEs) ensures that each solution is customized to meet the unique 
          demands of our clients.
        </p>
        <div className="grid md:grid-cols-3 gap-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <FaLightbulb className="text-3xl text-blue-500 mb-2" />
            <h3 className="font-semibold mb-2">Innovative Solutions</h3>
            <p className="text-sm text-gray-600">Cutting-edge technologies tailored to your needs</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <FaUsers className="text-3xl text-green-500 mb-2" />
            <h3 className="font-semibold mb-2">Expert Team</h3>
            <p className="text-sm text-gray-600">Diverse COEs ensuring comprehensive solutions</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <FaRocket className="text-3xl text-purple-500 mb-2" />
            <h3 className="font-semibold mb-2">Accelerated Transformation</h3>
            <p className="text-sm text-gray-600">Rapid implementation for faster business growth</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-bold mb-4">Let's Transform Your Business Together</h2>
        <p className="text-gray-700 mb-4">
          Are you ready to take your organization to the next level? AeroAegis Inc. is here to help. 
          Contact us today to explore how our consulting and managed services can accelerate your 
          digital transformation journey.
        </p>
        <p className="text-gray-700 mb-4">
          Visit <a href="http://www.aeroaegis.com" className="text-blue-500 hover:underline">www.aeroaegis.com</a> or 
          reach out to our experts for a personalized consultation. Together, we can build innovative, 
          resilient, and scalable solutions that drive business success.
        </p>
        <p className="text-gray-700 font-semibold">
          Let's shape the future—one solution at a time.
        </p>
      </div>
    </div>
  );
};

export default CaseStudyPage;

