import React from "react";
import HeroSection from "./HeroSection";
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature3 from "./Feature3";
import Feature4 from "./Feature4";
import Feature5 from "./Feature5";
import Feature6 from "./Feature6";
import Feature7 from "./Feature7";
import Feature8 from "./Feature8";
import Feature9 from "./Feature9";
import Section1 from "./Section1";
import FinalSection from "./FinalSection";
import Feature10 from "./Feature10";
import Feature11 from "./Feature11";

export default function index() {
  return (
    <div>
      <HeroSection />
      <Section1/>
      <AnimationRevealPage>
        <Feature1 />
        <Feature2 />
        <Feature3 />
        <Feature4 />
        <Feature5 />
        <Feature6 />
        <Feature7 />
        <Feature8 />
        <Feature9 />
        <Feature10/>
        <Feature11/>
      </AnimationRevealPage>
      <FinalSection/>
    </div>
  );
}
