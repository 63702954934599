import React from "react";
import final_img from "../../../assets/img/Products/AeroPerformai/aesthetic-background-with-abstract-neon-led-light-effect.jpg";
export default function FinalSection() {
  return (
    <div>
      <section class=" mt-5 px-4 py-8 lg:py-10 relative">
        <div
          class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
          style={{
            backgroundImage: `url(${final_img})`,
          }}
        ></div>
        <div className="max-w-2xl mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
          <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
          AeroPulse AI is more than a product; it's a promise to our customers to provide the most advanced, efficient, and user-friendly cloud performance management solution on the market. As we continue to evolve and adapt to the needs of the modern cloud landscape, we invite you to join us on this journey. 
          </p>
          <p className="relative tracking-wide font-semibold lg:text-3xl pt-2  text-white md:text-lg">
          Together, we can ensure that your cloud infrastructure is not just meeting the demands of today but is poised to excel in the future.
          </p>
        </div>
      </section>
    </div>
  );
}
