import React from 'react'

import business_img from '../../../assets/img/CloudMigration/business.jpg'

export default function MainSection() {
  return (
    <div>
	<section className="dark:bg-gray-800 dark:text-gray-100">
	  <span class="mb-10 block text-center text-2xl font-bold text-[#23307F]">
		Core Offerings
		<div>
		  <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black"></p>
		</div>
	  </span>
	  <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
          <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Cloud Transformation Journey
</h3>
				<p className="mt-3 text-lg dark:text-gray-400">
				The journey to cloud transformation with AeroAegis is a strategic process that starts with a 
thorough assessment of your current infrastructure, applications, and operational workflows. 
We recognize that every organization's path to the cloud is unique, and therefore, we offer 
bespoke strategies tailored to meet your specific business needs and goals. Our process 
includes:
        </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Assessment and Planning</h4>
							<p className="mt-2 dark:text-gray-400">
							Evaluating your existing systems and determining the most 
efficient migration strategy that aligns with your business objectives. This phase 
includes identifying which applications should be moved to the cloud, re-architected, or 
replaced.


              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Migration Execution</h4>
							<p className="mt-2 dark:text-gray-400">
							Leveraging our advanced toolsets and methodologies to ensure a 
smooth, secure, and efficient migration of your applications and data to the cloud. This 
includes minimizing downtime and ensuring data integrity throughout the process.
              </p>
						</div>
						
					</div>

					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Optimization and Management</h4>
							<p className="mt-2 dark:text-gray-400">
							Once the migration is complete, we focus on 
optimizing your cloud environment for performance, cost, and security. Our 
management services ensure ongoing operational excellence, with a keen focus on 
scalability and agility.

              </p>
						</div>
						
					</div>
					
				</div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={business_img}
                alt="marketing"
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
	</section>
  </div>
  )
}
