import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import HeroSection from './HeroSection'
import MainSection1 from './MainSection1'
import Feature1 from './Feature1'
import Feature2 from './Feature2'
import Feature3 from './Feature3'
import Feature4 from './Feature4'
import FinalQuote from './FinalQuote'

export default function index() {
  return (
    <div>
        <HeroSection/>
        <MainSection1/>
        <AnimationRevealPage>
        <Feature1/>
        <Feature2/>
        <Feature3/>
        <Feature4/>
        </AnimationRevealPage>
        <FinalQuote/>

    </div>
  )
}
