import React from "react";
import last from "../../assets/img/news/final.jpg";
import { useNavigate } from "react-router-dom";
export default function FinalSection() {
  const navigate = useNavigate();
  function handleOnClick() {
    navigate("/contact-us");
  }
  return (
    <section class=" ">
      <div class="relative group">
        <img class="object-cover w-full h-full sm:h-96" src={last} alt="" />
        <div class="absolute top-1/2 left-1/2 flex flex-col p-10 px-20 justify-center gap-y-9  bg-black bg-opacity-55 w-full h-full transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-4xl lg:text-6xl font-bold transition duration-300 opacity-100  ">
          <div className=" font-light text-4xl ">Get in touch</div>
          <div>
            <p className="lg:text-2xl font-normal md:text-md sm:text-xs text-xs">
              Empowering you with in-depth news coverage, analysis, and insights
              to help you navigate today's complex world with confidence and
              clarity with inside and outside Aeroaegis.
            </p>
          </div>
          <div>
            <p className="lg:text-2xl md:text-md font-normal text-xs">
              {" "}
              If you have questions or need more information,{" "}
              <div
                onClick={handleOnClick}
                className=" font-medium inline text-blue-500 hover:cursor-pointer hover:text-blue-600"
              >
                contact our media relations team
              </div>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
