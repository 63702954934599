import React from "react";
import discoverdata_vid from "../../../assets/videos/BigData/discoverData.mp4";
import deduceOutcome_vid from "../../../assets/videos/BigData/deduce.mp4";
import pattern_vid from "../../../assets/videos/BigData/pattern.mp4";
export default function MainSection1() {
  return (
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">What We Do</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-[#23307f] scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md">
            "Empowering Businesses &
            <span className=" ml-3"> Organizations by Governing Data."</span>
          </p>
        </div>
        <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={discoverdata_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-16 lg:mt-28 lg:text-center justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Discover Data</h1>
        </div> 
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">
                  Discover Data
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Collect, identify, and catalog business critical sensitive
                  data, so that it can be governed for meaningful purposes with
                  increased transparency.
                </p>
              </div>
            </div>
          </a>
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={pattern_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-16 lg:mt-28 lg:text-center justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Discern Pattern</h1>
        </div> 
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">
                  Discern Pattern
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Extracting relevant information in a large data set to
                  identify pattern by applying transformation.
                </p>
              </div>
            </div>
          </a>
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={deduceOutcome_vid}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-16 lg:mt-28 lg:text-center justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Deduce Outcome</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">
                  Deduce Outcome
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Extrapolate information by applying formulas and techniques to
                  establish relationship between variables.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
