import React from 'react'
import vision_img from '../../../assets/img/vision.png'
import goal_img from '../../../assets/img/goal.png'
import mission_img from '../../../assets/img/target.png'

export default function section_two() {
  return (
    <div className="grid gap-10 mt-16 mx-auto lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={mission_img}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">Mission</p>
            <p className="text-center text-gray-800">Our mission is to build cutting-edge AI and automation solutions that seamlessly integrate across cloud, edge, and IIoT environments, transforming businesses by optimizing operations, enhancing system resilience, and fostering sustainable growth and innovation.</p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={vision_img}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">Vision</p>
            <p className="text-center text-gray-800">To be the global leader in AI-powered digital transformation, enabling industries to achieve unparalleled operational excellence and resilience through intelligent, cloud and edge-integrated automation solutions.</p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={goal_img}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">Goal</p>
            <p className="text-center text-gray-800">Our goal is to provide enriching IT experience to businesses and customers using the power of Automation and AI.</p>
          </div>
        </div>
        </div>
  )
}
