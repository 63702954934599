import React from "react";

import pic1_img from '../../../assets/img/Profile/aravinda-profile-pic.jpeg'
import pic2_img from '../../../assets/img/Profile/bala_pic.jpg'

import pic3_img from '../../../assets/img/Profile/balasubramanian-palaniappan-profile-pic.jpeg'
import pic4_img from '../../../assets/img/Profile/venkat_profile_pic.jpeg'

export default function advisoryboards() {
  return (
    <div>
      <section className="bg-white ">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl ">
            Our <span className="text-[#242f7f]">Advisory Board Members</span>
          </h1>

          <div className="flex flex-wrap justify-center gap-8 mt-8 xl:mt-16">

            <div className="px-12 lg:w-[700px] py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 ">
              <div className="flex flex-col items-center sm:-mx-4 sm:flex-row">
                <div className="w-48 h-48 overflow-hidden rounded-full shadow-xl">
                  <img
                    className=" -translate-y-6"
                    src={pic1_img}
                    alt="img"
                  />
                </div>

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl  group-hover:text-white">
                    Dr. Aravinda C V
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize  group-hover:text-gray-300">
                    Advisory Board Member
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                Dr. Aravinda C V, a distinguished member of our Advisory Board, is a highly respected Professor at NMAMIT and Nitte institutions. With an extensive background in academia and research, Dr. Aravinda brings a wealth of knowledge in the fields of Artificial Intelligence (AI), Machine Learning (ML), Natural Language Processing (NLP), generative AI, and advanced BERT models. Holding a doctoral degree, he has made significant contributions to these domains, both through his scholarly work and practical applications. In addition to his academic role, Dr. Aravinda is currently working as a Project Manager at NIDEC COMPANY COE India, where he spearheads cutting-edge projects that integrate AI and ML into real-world solutions. His leadership and innovative approaches in AI have been instrumental in driving forward critical initiatives at NIDEC, making him a key figure in the industry. His expertise continues to be a driving force behind AeroAegis's mission to push the boundaries of technology.
              </p>

              <div className="flex text-gray-500  mt-4 mx-1">
                <a href="https://www.linkedin.com/in/dr-aravinda-c-v-b21a5115/">
                <svg
                   className="w-6 h-6 fill-sky-600 group-hover:fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
               
                  >
                    <path  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </div>
            </div>





            <div className="px-12 lg:w-[700px] py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 dark:border-gray-700 dark:hover:border-transparent">
              <div className="flex flex-col items-center sm:-mx-4 sm:flex-row">
                <img
                  className="flex-shrink-0 object-cover w-48 h-48 shadow-lg rounded-full sm:mx-4"
                  src={pic2_img}
                  alt="img"
                />

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl dark:text-white group-hover:text-white">
                    Balasubramanian Venkataraman
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                    Advisory Board Member
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                Balasubramanian Venkataraman offers over 20 years of expertise in financial operations, statutory compliance, and strategic management. His experience spans industries across Africa, India, and the USA. As a seasoned finance professional, Bala has excelled in project financing, debt restructuring, and implementing corporate policies to drive efficiency. He has held pivotal roles like CFO and Finance Director in multinational corporations and start-ups, where he led financial restructuring, managed ERP implementations, and optimized cash flow. Bala’s expertise in SAP FICO, MS-Dynamics AX, and other ERP systems has significantly enhanced financial reporting and operational processes. As an Advisory Board Member at Aeroaegis, Bala’s experience in strategic financial management and international business operations offers insights that support scaling operations, optimizing financial strategies, and navigating global markets.
              </p>

              <div className="flex text-gray-500  mt-4 mx-1">
                <a href="https://www.linkedin.com/in/balasubramanianvenkataraman/">
                <svg
                   className="w-6 h-6 fill-sky-600 group-hover:fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"

                  >
                    <path  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </div>
            </div>





            <div className="px-12 lg:w-[700px] py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 dark:border-gray-700 dark:hover:border-transparent">
              <div className="flex flex-col items-center sm:-mx-4 sm:flex-row">
                <img
                  className="flex-shrink-0 object-cover w-48 h-48 shadow-lg rounded-full sm:mx-4"
                  src={pic3_img}
                  alt="img"
                />

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl dark:text-white group-hover:text-white">
                    Balasubramanian Palaniappan
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                    Advisory Board Member
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                Balasubramanian Palaniappan, an esteemed member of our Advisory Board, brings to AeroAegis a distinguished career spanning over two decades in the technology industry. His extensive experience and leadership have been pivotal in shaping the direction and success of numerous high-profile projects. As an Engineering Leader at Delta Air Lines, Balasubramanian has spearheaded transformative initiatives, particularly in the areas of cloud migration and development. His expertise has driven significant advancements, enhancing the company's technological capabilities. His deep understanding of cloud technologies and innovative development practices has made him a recognized authority in the field. Balasubramanian's contributions at Delta Air Lines have set new standards for efficiency and scalability in aviation. His strategic insights and hands-on experience are instrumental in guiding AeroAegis as the company strives for excellence and innovation in its pursuits.
              </p>

              <div className="flex text-gray-500  mt-4 mx-1">
                <a href="https://www.linkedin.com/in/vpbalasubramanian/">
                <svg
                   className="w-6 h-6 fill-sky-600 group-hover:fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
               
                  >
                    <path  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="px-12 lg:w-[700px] py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 dark:border-gray-700 dark:hover:border-transparent">
              <div className="flex flex-col items-center sm:-mx-4 sm:flex-row">
                <img
                  className="flex-shrink-0 object-cover w-48 h-48 rounded-full sm:mx-4 shadow-lg"
                  src={pic4_img}
                  alt="img"
                />

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl dark:text-white group-hover:text-white">
                    Venkatraghav Ramamurthy
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                    Advisory Board Member
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                Venkat Raghav, an accomplished Advisory Board Member, has over 25 years of experience in the IT industry. Currently serving as a Solution Architect at Tech Mahindra, he has previously held significant roles at Hewlett Packard and Oracle, where he developed extensive expertise in advanced technologies. His background includes deep knowledge in AI/ML/NLP, Big Data analytics, and streaming platforms like Kafka. Venkat excels in managing and architecting multi-cloud environments, including AWS, Azure, and GCP. His strong skills in solutioning and product design have been pivotal in driving innovation and ensuring project success. At AeroAegis, Venkat’s technical acumen and strategic vision are crucial in shaping the company’s direction. His contributions foster continuous growth and adaptability, ensuring AeroAegis remains at the forefront of technological advancements.
              </p>

              <div className="flex text-gray-500  mt-4 mx-1">
                <a href="javascript:void(0)">
                <svg
                   className="w-6 h-6 fill-sky-600 group-hover:fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
               
                  >
                    <path  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
