import React from 'react'
import { Link } from 'react-router-dom'
import vision_img from '../../assets/img/vision.png'
import goal_img from '../../assets/img/goal.png'
import mission_img from '../../assets/img/target.png'


export default function herosection3() {
  return (
        <div className="relative pb-20">
    <div aria-hidden="true" className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 ">
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 "></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 "></div>
    </div>
  
        <div className="relative pt-28 ml-auto">
        <div className="lg:w-2/3 text-center mx-auto ">
            <span  className="text-base text-gradient font-semibold uppercase aos-init aos-animate" >Welcome to <span className=' text-lg font-bold'>AeroAegis</span></span>
                <h1 className="text-gray-900  font-bold  text-5xl md:text-6xl xl:text-7xl">Shaping <span className="text-primary ">Reality</span> <br/>
                <span className='pl-1'>Enhancing</span><span className="text-primary "> IT.</span></h1>

                <p className="mt-8 text-lg text-gray-700 ">Pushing the boundaries of the possible</p>
                <div className="mt-16 flex flex-wrap justify-center gap-y-4 gap-x-6">
                <Link to="/aeroaegis-capabilities">   <a
              
                      className="relative flex h-11  items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                    >
                      <span className="relative text-base font-semibold text-white"
                        >Learn more</span
                      >
                    </a></Link>
                    {/* <a
                      href="#"
                      className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max"
                    >
                      <span
                        className="relative text-base font-semibold text-primary dark:text-white"
                        >Learn more</span
                      >
                    </a> */}
                </div>
                <section>
      {/* <div class="relative items-center w-full px-5 pt-24 pb-5 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-24">
        <div class="grid grid-cols-1 gap-12 md:grid-cols-3 lg:space-y-0 lg:text-center">
          <div>
            <div>
              <div class="flex items-center justify-center w-15 h-15 text-black rounded-xl lg:mx-auto">
              <img src={mission_img} width={50}></img>
              </div>
              <p class="mt-4 text-lg font-medium leading-6 text-black">
                Mission
              </p>
            </div>
            <div class="mt-4 text-base text-gray-700">
            Our mission is to empower businesses with innovative IT automation solutions that eliminate manual tasks, reduce errors, and optimize resource utilization.
            </div>
          </div>
          <div>
            <div>
              <div class="flex items-center justify-center w-15 h-12 text-black  rounded-xl lg:mx-auto">
               <img src={vision_img} width={70}></img>
              </div>
              <p class="mt-4 text-lg font-medium leading-6 text-black">
                Vision
              </p>
            </div>
            <div class="mt-4 text-base text-gray-700">
            To revolutionize the IT industry by providing cutting-edge automation solutions that streamline processes, enhance efficiency, and empower organizations to achieve their full potential.
            </div>
          </div>
          <div>
            <div>
              <div class="flex items-center justify-center w-15 h-12 text-black  rounded-xl lg:mx-auto">
              <img src={goal_img} width={65}></img>
              </div>
              <p class="mt-4 text-lg font-medium leading-6 text-black">
              Goal
              </p>
            </div>
            <div class="mt-4 text-base text-gray-700">
            Our goal is to provide enriching IT experience to businesses and customers using the power of Automation and AI.
            </div>
          </div>
        </div>
      </div> */}
    </section>
            </div>
            {/* <div className="mt-12 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
                <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
                    <img src={img5} className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" />
                  </div>
                <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
                  <img src={img1} className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" />
                </div>
                <div className="p-4 flex grayscale transition duration-200 hover:grayscale-0">
                  <img src={img4} className="h-9 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" />
                </div>
                <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
                    <img src={img2} className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" />
                  </div>
                  <div className="p-4 flex grayscale transition duration-200 hover:grayscale-0">
                    <img src={img6} className="h-8 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" />
                  </div>
                <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
                    <img src={img3} className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" />
                </div>
              </div> */}
        </div>
   
</div>
  
  )
}
