import React, { useRef } from 'react'
import development_vid from '../../assets/videos/Homepage/development.mp4'
import meeting_vid from '../../assets/videos/Homepage/meeting.mp4'
import rnd_vid from '../../assets/videos/Homepage/RnD.mp4'
import watch_vid from '../../assets/videos/Homepage/watch.mp4'
import operational_vid from '../../assets/videos/Homepage/operational.mp4'

export default function WhatweOffer() {
  const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null),useRef(null)];

  const handleMouseEnter = (id) => () => {
    const index = cardData.findIndex((card) => card.id === id);
    if (videoRefs[index].current) {
      videoRefs[index].current.play();
    }
  };

  const handleMouseLeave = (id) => () => {
    const index = cardData.findIndex((card) => card.id === id);
    if (videoRefs[index].current) {
      videoRefs[index].current.pause();
      videoRefs[index].current.currentTime = 0;
    }
  };

  const cardData = [
    {
      id: 1,
      title: 'Innovative Product Suite',
      videoSource: development_vid,
      
      content1:
        "At AeroAegis, our core mission is to deliver pioneering technology solutions that redefine industry standards and propel businesses into the future. ",
      content2:"Our product suite - comprising Maturity Spectrum 360, SmartChaos Lab, AeroPulse AI, AeroInfra Vue, and AeroSys AR - is the embodiment of innovation, designed to address and transform your digital challenges into opportunities.",
    },
    {
      id:2,
      title: 'Customized Technological Solutions',
      videoSource: meeting_vid,
     
        content1:
        "Understanding the diversity of business challenges, AeroAegis products are engineered for flexibility. Our solutions adapt to your unique needs, ensuring seamless integration and alignment with your strategic objectives.",
        content2: "This bespoke approach guarantees that our products not only solve current challenges but also scale with your growth.",
    },
    {
      id:3,
      title: 'Strategic Implementation Support',
      videoSource: rnd_vid,
   
      content1:
        "Following a comprehensive assessment by Maturity Spectrum 360, AeroAegis extends beyond traditional product delivery. We offer strategic support services, including the implementation of recommended solutions, to ensure that the full potential of our products is realized within your enterprise.",
        content2:"This not only includes technical deployment but also encompasses process optimization and workforce upskilling to embrace the new solutions.",
    },
    {
      id:4,
      title: 'Research & Development for Continuous Advancement',
      videoSource:
        watch_vid,
      
      content1:
        "Our dedication to innovation is sustained by a robust Research & Development (R&D) effort, focusing on the continuous evolution of our products and the exploration of new technological frontiers.", 
      content2:"This commitment ensures that AeroAegis solutions remain at the cutting edge, offering you future-proof technologies that anticipate and adapt to the evolving digital landscape.",
    },
    {
      id:5,
      title: 'Operational Excellence and Reliability',
      videoSource:
        operational_vid,
      
      content1:
        "Operational services tied to our products provide an additional layer of value, ensuring that the integration and ongoing management of our solutions are seamless, secure, and efficient. ", 
      content2:"By taking care of the operational intricacies, we enable you to concentrate on strategic business objectives, with the confidence that AeroAegis solutions are enhancing your operational efficiency and system reliability.",
    },
    
  ];

  return (
    <div className="bg-gray-50  px-4 py-16 mx-auto sm:max-w-xl md:max-w-full  md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:justify-center lg:flex-row md:mb-0">
        
      <h2 className="max-w-4xl mb-5  text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-2 group">
          <span className="inline-block text-center mb-1 sm:mb-4">What We Offer: <span className='text-[#252f80]'>Revolutionary Products </span> at the Core of Digital Transformation</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-[#252f80] scale-x-30 group-hover:scale-x-100" />
       
        </h2>
        
        
      </div>

      <div >
        <p className="py-5 px-10 lg:px-36 text-center text-lg text-gray-700 ">
        At AeroAegis, we offer more than just products; we provide solutions that drive transformation. Our suite of innovative tools is designed to meet the dynamic needs of businesses, enabling them to thrive in an ever-evolving digital landscape. From digital transformation and cloud resilience to application performance management and infrastructure design, our products stand at the forefront of technological advancement.
        </p>
        </div>
      <div className="flex flex-wrap justify-center gap-2 row-gap-5 mb-8  sm:row-gap-6 ">
        {cardData.map((card) => (
          <a
            key={card.id}
            aria-label="View Item"
            onMouseEnter={handleMouseEnter(card.id)}
            onMouseLeave={handleMouseLeave(card.id)}
          >
             <div className={`relative group h-[300px] lg:h-[720px] overflow-hidden transition duration-700 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl ${card.id === 5 ? 'lg:w-[1450px] lg:h-[500px]' : 'lg:w-[720px]'}`}>
              {card.videoSource ? (
                <video
                  ref={videoRefs[card.id - 1]}
                  className="object-cover  w-full h-full lg:h-full md:h-full   "
                  muted
                  loop
                >
                  <source src={card.videoSource} type="video/mp4" />
                </video>
              ) : (
                <img
                  className="object-cover w-full h-64 md:h-64 xl:h-80"
                  src={card.imageSource}
                  alt=""
                />
              )}
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-24 md:text-4xl md:mt-72 lg:mt-72  text-center lg:text-center justify-center font-semibold align-content-center text-xl lg:text-5xl group-hover:hidden'>{card.title}</h1>
        </div>
              <div className="absolute  inset-0 px-6 py-2 transition-opacity duration-700 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <div className=' py-5 text-[12px] lg:text-lg md:text-base '>
                <p className="font-bold text-gray-100 pb-2 md:text-xl lg:text-2xl lg:pb-5">
                  {card.title}
                </p>
                <p className="  tracking-wide text-gray-300">
              
                  {card.content1}
                </p>
                <p className=" mt-3  tracking-wide text-gray-300">
           
                  {card.content2}
                </p>
                </div>
               
              </div>
            </div>
          </a>
        ))}
      </div>
  
    </div>
  );
}
