import React from "react";
import model_video from "../../../assets/videos/vfxAnimation/model.mp4";
import matte_video from "../../../assets/videos/vfxAnimation/matte-paint.mp4";
import photography_video from "../../../assets/videos/vfxAnimation/photography.mp4";
export default function Mainsection1() {
  return (
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">What We Do</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-[#23307f] scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md">
            "Engage Audiences by Creating and Developing Visual Effects and
            Animation for Businesses and Organizations."
          </p>
        </div>

        <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={model_video}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-10 lg:mt-28 text-center justify-center font-semibold align-content-center text-3xl group-hover:hidden'>2D or 3D Modeling</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-4 text-lg font-bold text-gray-100">
                  2D or 3D Modeling
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Our mission is to empower businesses with innovative IT
                  automation solutions that eliminate manual tasks, reduce
                  errors, and optimize resource utilization.
                </p>
              </div>
            </div>
          </a>
          <a  aria-label="View Item">
            <div className="relative group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={matte_video}
                alt=""
              />
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white flex mt-10 lg:mt-28 text-center justify-center font-semibold align-content-center text-3xl group-hover:hidden'>Matte Painting</h1>
        </div>
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-2 text-lg font-bold text-gray-100">
                  Matte Painting
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  To revolutionize the IT industry by providing cutting-edge
                  automation solutions that streamline processes, enhance
                  efficiency, and empower organizations to achieve their full
                  potential.
                </p>
              </div>
            </div>
          </a>
          <a  aria-label="View Item">
            <div className="relative  group overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <video
                muted
                loop
                autoPlay
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={photography_video}
                alt=""
              />
              <div className="absolute  inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-white text-center flex mt-10 lg:mt-28 lg:text-center justify-center font-semibold  align-content-center text-3xl group-hover:hidden'>Principal and Reference Photography</h1>
        </div>
              <div className="absolute inset-0 px-6 py-5 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-2 text-lg font-bold text-gray-100">
                  Principal and Reference Photography
                </p>
                <p className="text-[13px] lg:text-base tracking-wide text-gray-300">
                  Our goal is to provide enriching IT experience to businesses
                  and customers using the power of Automation and AI.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
