import React from 'react'
import hero_img from "../../../assets/img/Services/BigData/pexels-christina-morillo-1181354.jpg"

export default function HeroSection() {
  return (
    <div>
     <section className=" mt-10 mx-auto max-w-screen-xl pb-4 px-4 items-center lg:flex md:px-8">
                <div className="space-y-4 flex-1 sm:text-center lg:text-left">
                
                    <h1 className="text-gray-800 font-bold text-4xl xl:text-5xl">
                    Data Insights & Analytics
                       
                    </h1>
                    <div class="flex w-24 mt-1 mb-6 overflow-hidden rounded">
                        <div class="flex-1 h-2 bg-blue-200">
                        </div>
                        <div class="flex-1 h-2 bg-blue-400">
                        </div>
                        <div class="flex-1 h-2 bg-blue-600">
                        </div>
                    </div>
                     
                </div>
                <div className="flex-1 text-center shadow-xl mt-4 lg:mt-0 lg:ml-3">
                    <img src={hero_img} className="w-full mx-auto   lg:w-full" />
                </div>
            </section>
  </div>
  )
}
