import React from 'react'
import { Link } from 'react-router-dom';
import city_img from '../../assets/img/Whitepapers/digital.jpg'
export default function Section1() {
  return (
    <div>
      <section class="relative flex items-center w-full bg-white">
        <div class="relative items-center w-full px-5 lg:py-24 mx-auto md:px-12 lg:px-16 max-w-7xl">
          <div class="relative flex-col items-start m-auto align-middle">
            <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-24">
              <div class="relative items-center gap-12 m-auto lg:inline-flex">
                <div class="max-w-xl text-center lg:text-left">
                  <div>
                    <p class="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                      Navigating Digital Transformation: Unleashing the Power of
                      Maturity Spectrum 360
                    </p>
                    <p class="max-w-xl mt-4 text-base lg:text-lg tracking-tight text-gray-700">
                      This white paper delves into the significance of digital
                      transformation, elucidating the complexities organizations
                      face. We explore how Maturity Spectrum 360 acts as a
                      catalyst for this transformation journey, integrating
                      cutting-edge AI algorithms, customizable modules, and
                      seamless integration capabilities. Through real-life case
                      studies and success stories, we demonstrate the tangible
                      impact that Maturity Spectrum 360 has had on
                      organizations, driving innovation and growth. As we
                      navigate the dynamic IT landscape, Maturity Spectrum 360
                      stands at the forefront of change.
                    </p>
                  </div>
                  <div class="flex justify-center gap-3 mt-10 lg:justify-start">
                    <a
                      class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600"
                  
                    >
                    <Link to="/navigating-digital-transformation-unleashing-power-maturity-spectrum-360"><a> <span> Read more → </span></a></Link>  
                    </a>
                  </div>
                </div>
              </div>
              <div class="order-first block w-full mt-5  lg:mt-0 lg:order-first">
                <img
                  class="object-cover object-center w-full h-[300px] md:h-[400px] lg:h-[500px] mx-auto bg-gray-300 lg:ml-auto"
                  alt="hero"
                  src={city_img}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
