import React from 'react'
import final_img from "../../../assets/img/Products/InfraVue/pexels-torsten-dettlaff-347696.jpg"
export default function FinalQuote() {
  return (
    <div>
          <section class=" mt-5  pt-20 pb-20 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
            style={{
              backgroundImage: `url(${final_img})`,
            }}
          ></div>
          <div className="max-w-2xl px-4 mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-3xl text-white md:text-lg">
            We envision a future where organizations, regardless of their size or technical prowess, can leverage the power of infrastructure as never before.We invite you to join us on this transformative journey, to discover the future of infrastructure, and to embrace the promise of AeroInfra Vue—a world where "Bringing Vision to Infrastructure" is not just a tagline but a reality.
            </p>
          </div>
        </section>
    </div>
  )
}
