import React from 'react'
import hero_img from '../../../assets/img/Products/AeroPerformai/hero.png'
import logo_img from '../../../assets/img/Products/AeroPerformai/aeroperform_logo.svg'
export default function HeroSection() {
  return (
    <div>
    <section className="relative flex flex-col items-center w-full bg-white">
      <div className="w-full px-5 py-8 mx-auto lg:px-16 lg:py-10 max-w-7xl md:px-12">
        <div className="grid gap-4 lg:flex lg:flex-row-reverse lg:gap-24 lg:grid-cols-2">
          <div className="flex justify-center w-full md:justify-center md:flex lg:mt-0 lg:hidden">
            <div className="flex items-center">
              <img
                src={logo_img}
                alt="Aero Perform Image"
                className="w-16 h-auto mr-1"
              />
              <p className="font-bold text-lg">AeroPulse AI</p>
            </div>
          </div>

          {/* hero_img */}
          <div className="block w-full">
            <img
              alt="hero"
              className="object-cover object-center w-40 lg:w-full mt-20 mx-auto drop-shadow-xl lg:ml-auto rounded-2xl"
              src={hero_img}
            />
          </div>

          {/* Content for small screens */}
          <div className="block w-full lg:hidden">
            <div className="max-w-xl text-center">
            <p class="text-3xl md:pl-20 font-medium md:text-6xl text-slate-900">
            <span class="text-[#252f80]">AeroPulse AI:</span>{' '}
                Your Next-Generation Cloud Performance Partner
              </p>
              <p className="mt-4 text-lg tracking-tight text-gray-700 lg:text-xl md:pl-20">
              Welcome to AeroPulse AI, it's a strategic asset for any business committed to achieving excellence in cloud infrastructure management. By choosing AeroPulse AI, you're not only optimizing your current operations but also paving the way for future growth and success.
                </p>
            </div>
            <div className="flex flex-col items-center gap-3 mt-10 lg:flex-row">
                <a className="inline-flex rounded-lg items-center justify-center px-6 py-4 text-center text-white duration-200 bg-[#252f80] hover:bg-blue-800" href="#_">
                  <span className="font-semibold">Buy Now</span>
                </a>
              </div>
          </div>

          {/* Content for larger screens */}
          <div className="relative items-center gap-12 m-auto lg:inline-flex hidden lg:flex">
            <div className="max-w-xl lg:max-w-6xl text-center lg:text-left">
              <div className="flex items-center">
                <img
                  src={logo_img}
                  alt="SmartChaosLab Image"
                  className="w-20 h-auto mr-1"
                />
                <p className="font-bold text-lg">AeroPulse  AI</p>
              </div>
              <div>
              <p class="text-3xl font-medium md:text-6xl text-slate-900"><span> Introducing </span>
                <span class="text-[#252f80]">AeroPulse AI:</span>{' '}
                Your Next-Generation Cloud Performance Partner
              </p>
                <p className="mt-4 text-lg tracking-tight text-gray-700 lg:text-xl">
                Welcome to AeroPulse AI, it's a strategic asset for any business committed to achieving excellence in cloud infrastructure management. By choosing AeroPulse AI, you're not only optimizing your current operations but also paving the way for future growth and success.
                </p>
              </div>
              <div className="flex flex-col items-center gap-3 mt-5 lg:flex-row">
                <a className="inline-flex items-center rounded-lg justify-center px-6 py-4 text-center text-white duration-200 bg-[#252f80] hover:bg-blue-800" href="#_">
                  <span className="font-semibold">Buy Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}
