import React from 'react'
import Herosection from './Herosection'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import OtherSection9 from './OtherSection9'
import AnimationRevealPage from "../../helpers/AnimationRevealPage";

export default function index() {
  return (
    <div>
      <Herosection/>
      <AnimationRevealPage>
      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
      </AnimationRevealPage>
      <OtherSection9/>
    </div>
  )
}
