import React from 'react'
import img1 from '../../../assets/img/Profile/bhanthe_bodhidatta_pic.jpeg'
export default function IndependentDirector() {
  return (
    <div>
          <h1 className="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">
        <span className="text-[#242f7f]">Independent Director </span>
      </h1>
        <div class="p-4">
       
    <div class="mb-4 text-center opacity-90">
        <a href="#" class="relative block">
            <img alt="profile" src={img1} class="mx-auto object-cover shadow-lg rounded-full h-48 w-48 "/>
        </a>
    </div>
    <div class="text-center">
        <p class="text-2xl text-gray-800 dark:text-white">
        Bhante Bodhidatta
        </p>
        <p class="text-xl  text-gray-500 dark:text-gray-200">
        Board of Director
        </p>
        <p class=" py-4  text-gray-500 text-base lg:text-lg dark:text-gray-400">
        Bhante Bodhidatta is a veteran Educationist.
        </p>
    </div>
    {/* <div class="flex items-center justify-center pt-8 mx-auto text-gray-500 border-t border-gray-200 w-44">
    <a href="#">
    <svg
                   className="w-6 h-6 fill-sky-600 group-hover:fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
               
                  >
                    <path  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
        </a>
       
    </div> */}
</div>
</div>
  )
}
