import React from 'react'
import img from '../../../assets/img/SREDvops/monitoring.jpg'
export default function MainSection6() {
  return (
    <div>
         <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
            <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
			Monitoring and Observability
            </h3>
            <p className="mt-3 text-lg dark:text-gray-400">
			Essential to operational excellence is the ability to monitor
systems in real-time and gain actionable insights into their performance. Our solutions,
equipped with sophisticated monitoring and analytics capabilities, provide a comprehensive
view of the infrastructure, applications, and services. This visibility enables quick decisionmaking and ensures that performance benchmarks are consistently met.
            </p>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
    </div>
  )
}
