import React from 'react'
import scientific_img from '../../../assets/img/CloudMigration/2150010138.jpg'
export default function MainSection2() {
  return (
	<div>
	<div className="container flex flex-col place-items-center mx-auto lg:flex-row">
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={scientific_img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96"
              />
            </div>
          </div>
          <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
          <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Cloud-Native Development
</h3>
				<p className="mt-3 text-lg dark:text-gray-400">
				Adopting a cloud-native development approach is pivotal in achieving true digital 
transformation. AeroAegis excels in facilitating this shift, enabling businesses to build and 
deploy scalable applications that are designed to leverage the inherent advantages of cloud 
computing. Our cloud-native development services encompass:

        </p>
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Microservices Architecture</h4>
							<p className="mt-2 dark:text-gray-400">
							Designing and implementing applications as a collection 
of loosely coupled services, which improves modularity and makes the application 
easier to develop, deploy, and scale.
              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">Containerization and Orchestration</h4>
							<p className="mt-2 dark:text-gray-400">
							Utilizing container technologies like Docker and 
orchestration tools such as Kubernetes to manage containerized applications 
efficiently, enhancing deployment speeds and operational flexibility.

              </p>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-rose-400 dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi dark:text-gray-50">DevOps Integration</h4>
							<p className="mt-2 dark:text-gray-400">
							Embedding DevOps practices into the development lifecycle to 
foster a culture of continuous integration and continuous delivery (CI/CD), accelerating 
innovation and responsiveness to market changes.
              </p>
						</div>
					</div>
				</div>
          </div>
        </div>
</div>
  )
}
