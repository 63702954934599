import React from 'react'
import last from '../../assets/img/Investor_relations/last.jpg'
export default function FinalSection() {
  return (
    <section class=" ">
    <div class="relative group">
<img
class="object-cover w-full h-full sm:h-96"
src={last}
alt=""
/>
<div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-4xl lg:text-6xl font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
<p className=' text-center my-24 md:my-40 lg:my-36'>Join Us on the Journey</p>
</div>
<div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition duration-700  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
<p className='absolute text-center text-white text-[9px] md:text-lg lg:text-2xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
By investing in AeroAegis, you become a partner in our quest to redefine the IT landscape. Our team, led by industry veterans, is dedicated to realizing our vision and delivering exceptional value to clients. With Maturity Spectrum 360 driving growth, our potential is limitless. Invest in AeroAegis today and shape the future of IT excellence. Your partnership propels us forward, enhancing businesses, one innovation at a time.
</p>
</div>
</div>
    </section>
  )
}
