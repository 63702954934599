import React from 'react'
import HeroSection from './HeroSection'
import MainSection1 from './MainSection1'

export default function index() {
  return (
    <div>
        <HeroSection/>
        <MainSection1/>
    </div>
  )
}
