import React from 'react'

export default function Content1() {
  return (
    <div className=' py-10 px-5'>
      <div class="max-w-4xl mx-auto bg-[#23307f] shadow-blue-800 shadow-2xl rounded-xl">
        <div className=' px-5 py-5'>
      <p className="text-base text-center text-white md:text-lg">
      At AeroAegis, our AI & ML Solutions are meticulously crafted to enhance the capabilities of
our innovative product suite through the integration of cutting-edge artificial intelligence
and machine learning technologies. Our focus is on transforming data into actionable
insights, optimizing operations, and driving strategic decision-making through advanced AI
methodologies including Large Language Models (LLMs), Natural Language Processing
(NLP), and Latent Variable Models (LVM). Here's how these technologies are applied across
our solutions, particularly highlighting the role they play within the context of our Maturity
Spectrum 360, which features the Spectra Insight Core Engine for recommendations.
             


       
        </p></div>
        </div>
    </div>
  )
}
