import React from 'react'

export default function Content1() {
  return (
    <div className=' py-10 px-5'>
      <div class="max-w-4xl mx-auto bg-[#23307f] shadow-blue-800 shadow-2xl rounded-xl">
        <div className=' px-5 py-5'>
      <p className="text-base text-center text-white md:text-lg">
      In an era where data is the cornerstone of strategic decision-making, AeroAegis harnesses the
power of Data Insights & Analytics to transform the way businesses interact with and derive
value from their information. Our approach integrates cutting-edge technologies and
methodologies to ensure that data isn't just collected, but also effectively analyzed and utilized
to drive actionable insights. This detailed exploration focuses on how we leverage big data
analytics on the cloud, utilizing a robust data pipeline, streaming technologies, and advanced
storage solutions to enhance our product offerings like Maturity Spectrum 360,
SmartChaosLab, and AeroSys AR.

       
        </p></div>
        </div>
    </div>
  )
}
